




body {
  line-height: 1.7;
  color: #8c92a0;
  font-weight: 300;
  font-size: 16px; }


.change-icons1:hover .change-icons{
  width:55px!important;
  height:60px!important
  /* background-color: rgb(247, 207, 71)!important; */
}


  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password a {
    color: #167bff;
  }










::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

.text-black {
  color: #000; }

.site-wrap:before {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.offcanvas-menu .site-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden; }
  .offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible; }

.btn {
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out !important;
  -o-transition: 0.2s all ease-in-out !important;
  transition: 0.2s all ease-in-out !important;
  top: 0;
  letter-spacing: .05em; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-secondary {
    background-color: #e6e7e9;
    border-color: #e6e7e9;
    color: #000; }
  .btn.btn-sm {
    font-size: 14px !important;
    padding: 10px 20px !important; }
  .btn.btn-primary {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: .2em; }
  .btn:hover {
    -webkit-box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    top: -2px; }

.form-control {
  height: 43px; }
  .form-control:active, .form-control:focus {
    border-color: #7971ea; }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.site-section {
  padding: 2.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 5em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  font-size: 30px;
  color: #25262a;
  position: relative; }
  .site-section-heading:before {
    content: "";
    left: 0%;
    top: 0;
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }
  .site-section-heading.text-center:before {
    content: "";
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }

.border-top {
  border-top: 1px solid #edf0f5 !important; }

.site-footer {
  padding: 4em 0; }
  .site-footer ul li {
    margin-bottom: 10px; }
    .site-footer ul li a {
      color: #5c626e; }
      .site-footer ul li a:hover {
        color: #7971ea; }
  .site-footer .footer-heading {
    font-size: 20px;
    color: #25262a; }

/* Navbar */
.site-navbar {
  background: #fff;
  margin-bottom: 0px;
  z-index: 1999;
  position: relative; }
  .site-navbar.transparent {
    background: transparent; }
  .site-navbar .site-navbar-top {
    /* border-bottom: 1px solid #f3f3f4; */
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      .site-navbar .site-navbar-top {
        padding-top: 40px;
        padding-bottom: 40px; } }
  .site-navbar .site-search-icon a span {
    display: inline-block;
    margin-right: 10px; }
  .site-navbar .site-search-icon a:hover {
    text-decoration: none; }
  .site-navbar .site-logo a {
    text-transform: uppercase;
    color: #25262a;
    letter-spacing: .2em;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 2px solid #25262a; */
   }
    .site-navbar .site-logo a:hover {
      text-decoration: none; }
  .site-navbar a {
    color: #868995; }
    .site-navbar a:hover {
      color: #3d3f45; }
  .site-navbar .icon {
    font-size: 20px; }
  .site-navbar .site-top-icons ul, .site-navbar .site-top-icons ul li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .site-navbar .site-top-icons ul li {
    display: inline-block; }
    .site-navbar .site-top-icons ul li a {
      margin-right: 10px; }
      .site-navbar .site-top-icons ul li a.site-cart {
        display: block;
        position: relative; }
        .site-navbar .site-top-icons ul li a.site-cart .count {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -15px;
          margin-top: -20px;
          font-size: 13px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 50%;
          display: block;
          text-align: center;
          background: black;
          color:white;
          -webkit-transition: .2s all ease-in-out;
          -o-transition: .2s all ease-in-out;
          transition: .2s all ease-in-out; }
      .site-navbar .site-top-icons ul li a:hover .count {
        -webkit-box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        margin-top: -22px; }
    .site-navbar .site-top-icons ul li:last-child a {
      padding-right: 0; }
  .site-navbar .site-top-icons ul .site-menu-toggle {
    font-size: 25px;
    position: relative;
    top: 2px;
    margin-left: 20px; }
  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #f3f3f4 !important; }
  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0; }
    .site-navbar .site-navigation .site-menu .active > a {
      color: #7971ea; }
    .site-navbar .site-navigation .site-menu a {
      text-decoration: none !important;
      font-size: 15px;
      display: inline-block; }
    .site-navbar .site-navigation .site-menu > li {
      display: inline-block;
      padding: 6px 16px; }
     
      .site-navbar .site-navigation .site-menu > li > a {
        /* padding: 10px 10px; */
        text-transform: uppercase;
        letter-spacing: .05em;
        color: white;
        font-size: 14px;
        font-weight: bold;

        text-decoration: none !important; } 
        @media (max-width: 768px) {
        .site-navbar .site-navigation .site-menu > li > a {
          /* padding: 10px 4px; */
          text-transform: uppercase;
          /* letter-spacing: .05em; */
          color: white;
          font-size: 14px;
          font-weight: bold;
        }
        .site-navigation  {
          margin-left : -60px !important;
          padding-right:  60px !important;
           }
      }
        .site-navbar .site-navigation .site-menu > li > a:hover {
          color: #7971ea; }
    .site-navbar .site-navigation .site-menu .has-children {
      position: relative; }
      .site-navbar .site-navigation .site-menu .has-children > a {
        position: relative;
        padding-right: 20px; }
        .site-navbar .site-navigation .site-menu .has-children > a:before {
          position: absolute;
          content: "\e313";
          font-size: 16px;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: 'icomoon'; }
      .site-navbar .site-navigation .site-menu .has-children .dropdown {
        visibility: hidden;
        opacity: 0;
        top: 100%;
        position: absolute;
        text-align: left;
        border-top: 2px solid #7971ea;
        -webkit-box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        border-left: 1px solid #edf0f5;
        border-right: 1px solid #edf0f5;
        border-bottom: 1px solid #edf0f5;
        padding: 0px 0;
        margin-top: 20px;
        margin-left: 0px;
        background: #fff;
        -webkit-transition: 0.2s 0s;
        -o-transition: 0.2s 0s;
        transition: 0.2s 0s; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown a {
          font-size: 16px;
          text-transform: none;
          letter-spacing: normal;
          -webkit-transition: 0s all;
          -o-transition: 0s all;
          transition: 0s all; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
          color: #7971ea !important; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
          list-style: none;
          padding: 0;
          margin: 0;
          min-width: 200px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
            padding: 9px 20px;
            display: block; }
            .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
              background: #f4f5f9;
              color: #25262a; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
            content: "\e315";
            right: 20px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
            left: 100%;
            top: 0; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
            background: #f4f5f9;
            color: #25262a; }
      .site-navbar .site-navigation .site-menu .has-children a {
        text-transform: uppercase; }
      .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
        color: #7971ea; }
      .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
        cursor: pointer; }
        .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
          -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
          margin-top: 0px;
          visibility: visible;
          opacity: 1; }

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  .site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
      float: right;
      margin-top: 8px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
        font-size: 40px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
          color: #25262a; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 20px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
        display: inline-block;
        text-transform: uppercase;
        color: #25262a;
        letter-spacing: .2em;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid #25262a; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
          text-decoration: none; }
  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px; }
  .site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
    .site-mobile-menu .site-nav-wrap a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: #212529; }
      .site-mobile-menu .site-nav-wrap a:hover {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap li {
      position: relative;
      display: block; }
      .site-mobile-menu .site-nav-wrap li.active > a {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
        background: #f8f9fa; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
        font-size: 12px;
        z-index: 20;
        font-family: "FontAwesome";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-180deg);
        -ms-transform: translate(-50%, -50%) rotate(-180deg);
        transform: translate(-50%, -50%) rotate(-180deg);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .site-mobile-menu .site-nav-wrap > li {
      display: block;
      position: relative;
      float: left;
      width: 100%; }
      .site-mobile-menu .site-nav-wrap > li > a {
        padding-left: 20px;
        font-size: 20px; }
      .site-mobile-menu .site-nav-wrap > li > ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        .site-mobile-menu .site-nav-wrap > li > ul > li {
          display: block; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > a {
            padding-left: 40px;
            font-size: 16px; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
            padding: 0;
            margin: 0; }
            .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
              display: block; }
              .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
                font-size: 16px;
                padding-left: 60px; }

/* Blocks */
.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .site-blocks-cover, .site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh - 174px); }
  .site-blocks-cover h1 {
    font-size: 30px;
    font-weight: 900;
    color: #000; }
    @media (min-width: 768px) {
      .site-blocks-cover h1 {
        font-size: 50px; } }
  .site-blocks-cover p {
    color: #333333;
    font-size: 20px;
    line-height: 35px; }
  .site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5; }

.site-blocks-1 {
  border-bottom: 1px solid #edf0f5; }
  .site-blocks-1 .divider {
    position: relative; }
    .site-blocks-1 .divider:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      right: 10px;
      background: #edf0f5; }
    .site-blocks-1 .divider:last-child:after {
      display: none; }
  .site-blocks-1 .icon span {
    position: relative;
    color: #7971ea;
    top: -10px;
    font-size: 50px;
    display: inline-block; }
  .site-blocks-1 .text h2 {
    color: #25262a;
    letter-spacing: .05em;
    font-size: 18px; }
  .site-blocks-1 .text p:last-child {
    margin-bottom: 0; }

.site-blocks-2 .block-2-item {
  display: block;
  position: relative; }
  .site-blocks-2 .block-2-item:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
    background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .site-blocks-2 .block-2-item .image {
    position: relative;
    margin-bottom: 0;
    overflow: hidden; }
    .site-blocks-2 .block-2-item .image img {
      margin-bottom: 0;
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
  .site-blocks-2 .block-2-item .text {
    z-index: 2;
    bottom: 0;
    padding-left: 20px;
    position: absolute;
    width: 100%; }
    .site-blocks-2 .block-2-item .text > span, .site-blocks-2 .block-2-item .text h3 {
      color: #fff; }
    .site-blocks-2 .block-2-item .text > span {
      font-size: 12px;
      letter-spacing: .1em;
      font-weight: 900; }
    .site-blocks-2 .block-2-item .text h3 {
      font-size: 40px; }
  .site-blocks-2 .block-2-item:hover .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }

.block-3 .owl-stage {
  padding-top: 40px;
  padding-bottom: 40px; }

.block-3 .owl-nav {
  position: relative;
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  .block-3 .owl-nav .owl-prev, .block-3 .owl-nav .owl-next {
    position: relative;
    display: inline-block;
    padding: 20px;
    font-size: 30px;
    color: #5c626e; }
    .block-3 .owl-nav .owl-prev:hover, .block-3 .owl-nav .owl-next:hover {
      color: #25262a; }
    .block-3 .owl-nav .owl-prev.disabled, .block-3 .owl-nav .owl-next.disabled {
      opacity: .2; }

.block-4 {
  -webkit-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .block-4 .block-4-text h3 {
    font-size: 20px;
    margin-bottom: 0; }
    .block-4 .block-4-text h3 a {
      text-decoration: none; }

.block-5 ul, .block-5 ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5; }

.block-5 ul li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 15px;
  color: #25262a; }
  .block-5 ul li:before {
    top: 0;
    font-family: "icomoon";
    content: "";
    position: absolute;
    left: 0;
    font-size: 20px;
    line-height: 1;
    color: #7971ea; }
  .block-5 ul li.address:before {
    content: "\e8b4"; }
  .block-5 ul li.email:before {
    content: "\f0e0"; }
  .block-5 ul li.phone:before {
    content: "\f095"; }

.block-6 {
  display: block; }
  .block-6 img {
    display: block; }
  .block-6 h3 {
    font-size: 18px; }
  .block-6 p {
    color: #737b8a; }

.block-7 .form-group {
  position: relative; }

.block-7 .form-control {
  padding-right: 96px; }

.block-7 .btn {
  position: absolute;
  width: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 3px; }

.block-8 .post-meta {
  color: #c4c7ce; }

.block-8 .block-8-sep {
  margin-left: 10px;
  margin-right: 10px; }

.site-blocks-table {
  overflow: auto; }
  .site-blocks-table .product-thumbnail {
    width: 200px; }
  .site-blocks-table thead th {
    padding: 30px;
    text-align: center;
    border-width: 1px !important;
    vertical-align: middle;
    color: #212529;
    font-size: 18px; }
  .site-blocks-table td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    color: #212529; }
  .site-blocks-table tbody tr:first-child td {
    border-top: 1px solid #7971ea !important; }

.site-block-order-table th {
  border-top: none !important;
  border-bottom-width: 1px !important; }

.site-block-order-table td, .site-block-order-table th {
  color: #000; }

.site-block-top-search {
  position: relative; }
  .site-block-top-search .icon {
    position: absolute;
    right: -2.5%;
    top: 39%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .site-block-top-search input {
    padding-left: 40px;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
    .site-block-top-search input:focus, .site-block-top-search input:active {
      padding-left: 25px; }

.site-block-27 ul, .site-block-27 ul li {
  padding: 0;
  margin: 0; }

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px; }
  .site-block-27 ul li a, .site-block-27 ul li span {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .site-block-27 ul li.active a, .site-block-27 ul li.active span {
    background: #7971ea;
    color: #fff;
    border: 1px solid transparent; }

#slider-range {
  height: 8px; }
  #slider-range .ui-slider-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none !important;
    background: #7971ea; }
    #slider-range .ui-slider-handle:focus, #slider-range .ui-slider-handle:active {
      outline: none; }
  #slider-range .ui-slider-range {
    background-color: #7971ea; }

.color-item .color {
  width: 14px;
  height: 14px; }

.block-16 figure {
  position: relative; }
  .block-16 figure .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 40px;
    width: 90px;
    height: 90px;
    background: #fff;
    display: block;
    border-radius: 50%;
    border: none; }
    .block-16 figure .play-button:hover {
      opacity: 1; }
    .block-16 figure .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-50%, -45%);
      -ms-transform: translate(-50%, -45%);
      transform: translate(-50%, -45%); }

.block-38 .block-38-header .block-38-heading {
  color: #000;
  margin: 0;
  font-weight: 300; }

.block-38 .block-38-header .block-38-subheading {
  color: #b3b3b3;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: .1em; }

.block-38 .block-38-header img {
  width: 120px;
  border-radius: 50%;
  margin-bottom: 20px; }


  @media  (max-width: 996px) {
    .block-none-cato{
  display:  none;
    }
  }

  .logo-img{
    width:350px;
    height:150px;
  }
  .js-logo-clone{
    /* padding: 28px 7px; */
  }
  .site-navbar .site-logo a{
    /* width:350px; */
    height:150px !important;
    padding :  60px 20px;
  }
  
  /* @media  (max-width: 400px) {
    .js-logo-clone{
      padding: 15px 7px;
    }
    .logo-img{
      width:200px;
      height:50px;
    }
  } */

  /* Admin */

.admin-data-container{
    /* width : 220px ; */
    background : rgb(74, 76, 78);
    height : 150px;
    margin :  3px 7px;

}

.admin-card{
  margin : 5px 0px
}
.site-section-heading:before {
  left: 45%  !important;
}


.color-th{
  color :  black
}


.pop-up-1{
    background : black ;
    color : white ;
    width : 500px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 40%;
    left : 32% ;
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.4;
}




@media  (max-width: 500px) {

  .pop-up-1{
    background : black ;
    color : white ;
    width : 300px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 30% !important;
    left : 8% !important;
    /* top : 60%; */
    /* left : 32% ; */
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.7;
}

}













.change-header-color{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
}

.change-header-color33{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
  display: none;
}

/* .change-header-color1{ */
  /* color:rgb(238, 2, 138) !important; */
/* } */



.change-header-color{
  /* color:rgb(0, 240, 255) !important; */
  color: #000 !important;
}

/* .change-header-color1:hover{
  color:rgb(0, 240, 255);
} */


.arrival{
  overflow: hidden;
  max-width : 600px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 500px !important;
  margin: 20px 10px !important;

  /* margin : 15px 10px;
  width : 600px;
  height:500px;    
  overflow: hidden; */

  /* max-width : 650px;
  overflow: hidden; */

}

.arrival-Photo{
  /* width : 650px ;
  height : 400px  !important;
  transition: transform 2s, filter 1.5s ease-in-out; */


  width : 600px;
    height:500px;    
    transition: all 2s  ease-in-out;

  

}
.arrival-Photo1{
  /* max-width : 650px !important; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  /* overflow: hidden; */
  max-width : 367px !important;
}
.arrival1{
  overflow: hidden;
  max-width : 367px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 400px !important;
  margin: 20px 10px !important;

}

.arrival-Photo11{
  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px 10px !important; */
  max-width : 367px !important;
  /* padding-left : 40px; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  overflow: hidden;
}

.arrival-Photo:hover{
  /* filter: brightness(90%); */
  transform: scale(1.1);

}
.arrival-Photo1:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}
.arrival-Photo11:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}

@media screen and (max-width: 1199px) {
  /* .arrival-Photo{ */
    /* margin : 0px auto; */
    /* margin-left : 30px; */
    /* width : 70% !important; */
    /* overflow: auto; */
    /* transform: scale(1.4); */

  /* } */
  .arrival-Photo1{
    margin : 0px auto;
    margin-top : 40px;
    /* margin-left : 30px; */
    width : 1010px !important;
    /* transform: scale(1.4); */

  }
}
@media screen and (max-width: 800px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 550px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 550px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 600px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 470px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 470px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 520px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 400px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 400px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 450px) {
  /* .arrival-Photo{
    margin : 0px 10px;
    margin-left : 10px;
    max-width : 310px !important;
    padding-left : 20px;

    
  } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    max-width : 310px !important;
    padding-left : 20px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
  /* .arrival1{
    margin: auto !important;
    width : 80%;
  } */
  .arrival1{
    max-width : 300px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 300px !important;
  margin  : auto;

  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px auto !important;
  max-width : 270px !important;
  padding-left : 10px;
  height : 420px !important;
  padding-bottom: 20px !important;
  transition: transform 2s, filter 1.5s ease-in-out; */
}
}
@media screen and (max-width: 310px) {
  
  .arrival1{
    max-width : 250px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 250px !important;
  margin  : auto;

}
}
/* .backk{
  background: url("assets/images/back.jpg");

} */
.HeaderMainImp{
  padding-bottom: 20px; 
  /* background: url("assets/images/header-bg-rpt.jpg"); */
  background: white;
  color :  white;
  background-repeat: repeat-x;
  

}
.HeaderMainImp > p{
  margin: 0px 30px; 
  padding: 10px 0px;
}
.mobile-head-menu{
    height : auto ; 
    width:  100% ;
    background-color : rgb(255, 255, 255) !important;
    z-index : -1;
    margin : 0px;
    padding : 0px;
    /* border-bottom: 3px solid black; */


}
.mobile-head-menu > li{
    list-style-type: none;
    padding: 18px 30px;
    border-bottom: 5px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(114, 103, 103);
}
.mobile-nav-border {
  font-size: 23px !important;
    /* border-bottom: 3px solid black; */
    /* width : 150px !important; */
    padding:0px 40px 6px 0px !important;
    color : black !important;
    font-weight: 500 !important;
}
.part2-nav{
  padding-bottom: 0px !important;
}
.nested-drop-nav{
  margin : 0px 0px 0px 20px;
  /* color : black !important; */
  font-weight: 500 !important;
  /* padding  : */
}
.nested-drop-nav > li{
  list-style-type: none;
  padding : 15px;
}

.mobile-site-hide-show{
  /* visibility: hidden; */
  background:rgb(245, 245, 245) !important;
  display: none;
}

.desktop-nav{
  position:absolute;
  width : 30%;
  z-index: 99999;
  margin-left : 30%;
  background-color : rgb(207, 248, 152) !important;
  /* display: none; */
}
.desktop-nav-drop{
  height : auto ; 
   width: 40%;
}
.desktop-nav-drop > li{
  list-style-type: none;
  padding : 20px 10px ;
  font-size: 22px !important;
  font-weight: bold;
}
.desktop-nav-drop > li > Link{
  border-bottom: 1px solid #167bff;
}
.site-menu  > li{
  transition: transform 2s, filter 1.5s ease-in-out !important;
  transform-origin: center center;

}
.site-menu > li:hover{
  background-color: rgb(233, 184, 184);
}
.nav-menu-hover-effect:hover .desktop-nav{
  display:none ;
}
/* .desktop-nav{
  display: none ;
}
.desktop-nav:hover{
  display: block ;
} */

#desktop-nav1{
  display: none ;
}
#desktop-nav1:hover{
  display: block ;
}
.nav-menu-hover-effect:hover{
  background-color: #737b8a;
}



 
.logo-design{
  padding-right:50px !important;

}

.input-search-mobile{

  /* margin-left: 30px !important; */
}




@media screen and (max-width: 1200px) {
  
 
  /* .nav-icon-mobile{
    display: none;
    visibility: hidden;
  } */
    .logo-design{
    /* padding-right:150px !important; */
    margin-top:-30px !important ;
    margin-left:-40px !important ;
  
  }
  }

@media screen and (max-width: 800px) {
  

  .input-search-mobile{
    margin-top:  80px !important;
  }
  .logo-design{
    margin-left:0px !important ;
  
  }
  }
  
@media screen and (max-width: 520px) {
  
.logo-design{
  /* padding-right:150px !important; */
  padding-left:0px !important ;
  padding-right: 0px !important;


}
.input-search-mobile{
  margin-left:30px !important ;
  padding : 7px 10px;
  /* margin-top: 10px !important; */
}
.nav-icon-mobile{
  margin-top: -35px !important;
}
.input-search-mobile{
  margin: auto !important;
  margin-top:  40px !important;
}
}

@media screen and (max-width: 820px) {
.site-navigation{
  display: none;
}
/* .mobile-site-hide-show{
  display:  block;
} */
.change-header-color33{
  display:  block;
  height : auto !important;
  font-size: 29px !important;
}
}

@media screen and (min-width: 820px) {

.mobile-site-hide-show{
  display:  none;
}
#mobile-site-hide-show11{
  display:  none !important;
}

}






.shoes-type-Photo11{
  padding-left: 30px;
  width: 700px;
  height : 300px;
}


@media screen and (max-width: 1220px) {

  .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}


/* @media screen and () { */

 
/* } */

@media only screen and (max-width: 1400px) and (min-width: 1230px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 600px;
    height : 300px;
  }
}

@media only screen and (max-width: 1220px) and (min-width: 900px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}

@media only screen and (max-width: 899px) and (min-width: 680px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 330px;
    height : 200px;
  }
}
@media only screen and (max-width: 681px) and (min-width: 581px) {
 .shoes-type-Photo11{
    padding-left: 0px !important;
    width: 280px;
    height : 180px;
  }
}

@media only screen and (max-width: 580px) and (min-width: 480px) {
 .shoes-type-Photo11{
    padding-left: 70px !important;
    padding-top  : 30px;
    width: 400px;
    height : 180px;
  }
}

@media only screen and (max-width: 479px) and (min-width: 390px) {
 .shoes-type-Photo11{
    padding-left: 35px !important;
    padding-top  : 30px;
    width: 330px;
    height : 180px;
  }
}

@media only screen and (max-width: 389px) and (min-width: 250px) {
 .shoes-type-Photo11{
    /* padding-left: 35px !important; */
    padding-top  : 30px;
    width: 300px;
    height : 180px;
  }
}
@media only screen and (max-width: 189px) and (min-width: 300px) {
 
.pop-up-1{
  /* background : black ;
  color : white ;
  width : 500px;
  padding : 5px 10px ;
  border-radius : 10px ;
  margin :  5px ;
  position : fixed ; */
  /* top : 220% !important; */
  /* left : 32% ;
  z-index : 99999 ;
  visibility: hidden;
  opacity: 0.4; */
}
}


.Shoes-Size-Button {
  max-height : 150px;
  overflow-y: scroll;
}


.Shoes-Size-Button > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 1px solid grey ; 
  background:  transparent;

}


.Shoes-Size-Button1 > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 2px solid grey ; 
  background:  transparent;

}




.details-img-size{
  height:140px !important;
  padding-left: 20px !important;
  width : 33%  !important;
  /* background-size: cover;
  background-position: center;
  background-image: none; */
}

.container-photo-detail{
  width : 500px;
  height:500px;    
  overflow: hidden;
  /* margin: 0 auto; */

}
#changes-Photo{
    width : 500px;
    height:500px;    
    transition: all 2s  ease-in-out;
    /* background-size: cover;
    background-position: center;
    background-image: none;
    transform-origin: center center; */
    /* filter: brightness(100%); */
    /* transition: transform 2s, filter 1.5s ease-in-out; */
}

/* #/: scale(1.5); */
  /* width :  700px !important;
  height  : 700px; */
  /* max-width : 500px; */
  /* filter: brightness(90%); */
  /* transform: scale(1.4); */
    /* max-width : 500px; */
    /* transform: translate(50%); */

/* } */

@media only screen and (max-width: 469px) and (min-width: 321px) {
  .container-photo-detail{
    width : 330px !important;
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 330px !important;
    height:400px !important;    

}

}

@media only screen and (max-width: 320px) and (min-width: 150px) {
  .container-photo-detail{
    width : 300px !important;
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 300px !important;
    height:400px !important;    

}

}

.product-detail-descr-rating {
    padding  : 2px;
    border-radius: 30px;
}
.product-detail-descr-rating > div{
    background-color: rgba(0, 0, 0, 0.178);
    /* max-width : 100px !important; */
    padding  : 20px 30px;
}
.product-detail-descr-rating > div > p{
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
}

.product-detail-descr-rating1 {
  margin : 0px;
  padding  : 2px;
  border-radius: 30px;
}
.product-detail-descr-rating1 > div{
  border-radius: 10px;
  border : 2px solid grey;
  border-top: none;
  height : auto !important;
  /* background-color: rgba(0, 0, 0, 0.178); */
  /* max-width : 100px !important; */
  padding  : 20px 30px;
}


.product-detail-descr-rating3 {
  margin : 0px;
  padding  : 2px;
  border : 2px solid grey;
  border-top: none;
  border-radius: 30px;
  color  : black !important;
  font-weight: bold !important;
}

.product-detail-descr-rating3  > div > h3{
  margin-top : -15px;
  /* padding: 0px; */
}


.Icon-start{
  color : rgba(192, 186, 186, 0.432) ;
}
.a1-start:hover{
  color : blue !important;
}
.a2-start:hover{
  color : rgb(231, 255, 145) !important;
}
.a3-start:hover{
  color : rgb(39, 169, 230) !important;
}
.a4-start:hover{
  color : rgb(170, 63, 63) !important;
}
.a5-start:hover{
  color : rgb(14, 167, 35) !important;
}
.root{
  width : 100% !important;
}

.display-none-icon{
  display: none;
}


/* @media only screen and (max-width: 200px) and (min-width: 850px) {
  .display-none-icon{
    display: none;
  }
} */


@media only screen and (max-width: 200px) and (min-width: 999px) {
  .nav-icon-mobile{
    padding-top : 50px !important; 
}
   .change-header-color{
    position: absolute;
    right  : 10%
   }
   .change-header-color1{
    position: absolute;
    right   : 15%
   }
   .change-header-color33{
    position: absolute !important;
    left  : 8%
   }
   /* .navbar-header{
     width: auto !important;
   } */
}

@media  only screen and (min-width: 881px) and (max-width: 999px){

.navbar-header{
  width: auto !important;
}
}
@media  only screen and (min-width: 526px) and (max-width: 800px){
.input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    /* margin-top: 80px !important; */
}
.navbar-header{
  width: auto !important;
}
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .navbar-header{
    width: auto !important;
  }
  .jnjljlnj{
    right: -110px !important

  }
  .change-pixel{
    width: 50%;
    /* box-shadow: 3px 4px 13px -3px grey; */
    
  }
  .change-img-size{
    height: 139px !important;
    object-fit: cover !important;
  }
.site-block-top-search .icon {
    right: -3%;
    top: 23% !important;
     }
     .site-block-top-search input{
       padding-left: 0px !important;
     }
     .wsdsd {
      padding-top: 0px !important;
      margin-left: 14px !important;
     }
.site-navbar .site-navbar-top {
      height: 150px;
     }
  .input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 50px !important;
    /* margin-left: 14px !important; */
    
    /* margin-top: 80px !important; */
}
}

#view-at-hover{
  background-color: rgb(86, 97, 102) !important;
  visibility: hidden;
  transition: .2s all ease-in-out;
}
.view-btn-detail{
  padding : 10px 30px !important;
}

.hover-at-view{
  transition: .2s all ease-in-out;
  visibility: visible !important;
  
}
.hover-at-view:hover{
  background-color: skyblue !important;
}

.hover-product-card:hover{
  /* border: 1px solid grey; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  
}

.nav-icon-mobile45{
  padding-top: 25px !important;
  padding-left: 30px;
}

.dispaly-bllock-none{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 791px){
  .dispaly-bllock-none{
    display: block !important;
  }

.display-noen{
  display: none !important;
  
}

}
@media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }
  
}
@media  only screen and (min-width: 600px) and (max-width: 821px){
  .logo-img{
    margin-left: 91px;
  }
  .fglgiklkkllk1{
    display: none !important;
  }
  .fglgiklkkllk2{
    display: block !important;
  }

}
@media  only screen and (min-width: 500px) and (max-width: 768px){
  .logo-img{
    margin-left: 50% !important;
    width : 235px !important
  }
  .dispaly-bllock-none {
    position: absolute !important;
    right: -3% !important;
  }

}
/* @media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }

} */
@media  only screen and (min-width: 700px) and (max-width: 885px){
  .nav-icon-mobile45{
   padding-left: 0px !important;
  }

}
/* @media  only screen and (min-width: 717px) and (max-width: 991px){
  .logo-img{
    margin-left: 191px;
  }
 

} */

@media  only screen and (min-width: 586px) and (max-width: 716px){
 .logo-img{
      margin-left: 81px;
    width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 40px !important;
}

}

@media  only screen and (min-width: 400px) and (max-width: 585px){
 .logo-img{
      margin-left: 40px !important;
      width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 44px !important;
      padding-right: 0px !important;

}
.change-header-color33{
  padding-left: 20px;
}
.dispaly-bllock-none{
  margin-left: 40px;
}
}
@media  only screen and (min-width: 200px) and (max-width: 385px){
  .logo-img{
       margin-left: 37px;
       width : 240px;
  }
 }
@media (min-width: 576px){
.col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
}

@media  only screen and (min-width: 400px) and (max-width: 579px){
  .change-header-color33{
   font-size: 49px !important;
 }
}
@media  only screen and (min-width: 330px) and (max-width: 576px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 300px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  /* max-width :459px !important; */
  margin : auto;
 }
}

@media  only screen and (min-width: 330px) and (max-width: 400px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 270px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  max-width :329px !important;
  margin : auto;
 }
}

@media  only screen and (min-width: 230px) and (max-width: 329px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 250px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   padding-left: 40px !important;
   width : 230px !important;
 }
 .cart-width-change{
  width: 70px !important;
  position: absolute;
  right: 19%;
 }
 .input-width-change{
  width :309px !important;
 }
}

.border-0{
  padding-top: 20px !important;
}
.icon-search2{
   padding-top: 10px !important;
 }
 .logo-img {
  width: 163px;
    height: 67px;
}
.site-navbar .site-navbar-top {
  background: white;
  /* background: rgb(97 , 171 ,235); */
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.HeaderMainImp {
  height : 32px;
    padding-bottom: 0px;
    width: 100% !important;
}
.site-footer {
  padding: 0px;
}

#myDiv1234{
  display : none;
  width: 80% !important;
  height : 80% ;
  background-color: white;

}
#mainphoto{
  display : none;
  width: 100% !important;
  height : 100% ;
  background-color: rgb(190, 190, 190);

}
#myDiv23456{
  width: 70% !important;
  height : 80% ;
  position: absolute;
  right: 16%;
  top : 60%;
  z-index: 3;
}
#mainphoto2{
  width: 90% !important;
  height : 90% ;
  position: fixed;
  left: 3%;
  top : 5%;
  z-index: 5;
}
#photo33{
  /* right: 5%; */
  /* top : 180% !important; */
  position: fixed !important;
  z-index: 6;
  right: 2% !important;
  
  top: 7% !important;
}
@media  only screen and (min-width: 530px) and (max-width: 1029px){

  #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 5;
    height : 70% ;

  }
  #mainphoto2{
    /* right: 25%; */
    top : 5% !important;
    z-index: 3;
    /* height : 70% ; */

  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
  }
  #photo33{
    /* right: 5%; */
    /* top : 180% !important; */
    /* z-index: 3; */
    position: fixed;
  /* z-index: 4; */
  right: 2%;
  top: 8%;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
  #myDiv23456{
    right: 20%;
    top : 150% !important;
    z-index: 3;
    height: 60%;
    width : 90%;
  }
  #mainphoto2{
    /* right: 20%; */
    top : 10% !important;
    z-index: 3;

    left : 6% !important;
    width : 85% !important;
    height : 85% !important;
  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
    /* width : 90% !important; */

  }
  #photo33{
    position: fixed;
    /* z-index: 4; */
    right: .5%;
    top: 9% !important;

  }

  /* #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 3;
    height : 70% ;

  }
  #myDiv34567{
    right: 25%;
    top : 180% !important;
    z-index: 3;
  } */
  /* #myDiv23456 {
    width: 82% !important;
    height: 80%;
    position: absolute;
    right: 16%;
    top: 60%;
    z-index: 3;
}
  #myDiv1234{
    width: 90% !important;
  
  } */
  /* #myDiv23456{
    right: 3%;
    top : 180% !important;
    z-index: 3;
    height : 90% ;

  } */
  /* #myDiv34567{
    right: 3%;
    top : 180% !important;
    z-index: 3;
  } */
}
#myDivUmer{
  padding: 0px 0px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  /* top : 30%; */
  display : none;
  width: 46% !important;
  height : 49% ;
  background-color: white;
  /* margin-left : 30%; */
  border: 4px solid grey;
  bottom: 0px;
  right: 8%;

}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
 
  #myDivUmer{
    height:320px;
    width: 200px;
    margin-left : 165px;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
 
  #myDivUmer{
    height:300px !important;
    width: 300px !important;
    margin-left : 11%;
  }
}
@media  only screen and (min-width: 320px) and (max-width: 390px){
  .logo-img{
       margin-left: 38px;
       width : 234px !important;
  }
  .nav-icon-mobile45{
   /* padding-left: 70px !important;
       padding-right: 0px !important; */
    right: 13px;
    position: absolute;
    padding-top: 28px !important;
 }
}


@media  only screen and (min-width: 820px) and (max-width: 129px){
  .change-header-color33{
    display : block !important;
  }
 
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
  
.display-6{
  display: none;
}
  @media  only screen and (min-width: 120px) and (max-width: 429px){
    .dispaly-block{
      display: none;
    }
    .display-6{
      display: block;
    }
   .HeaderMainImp {
      width: 103% !important;
  }
  }
  @media  only screen and (min-width: 120px) and (max-width: 629px){
   
    .stext-104{
      width: 100% !important;
    }
  }




  .loader-1{
    position : fixed  ;
    left: 45%;
    top:20%;
    z-index: 9999;
  }


  @media  only screen and (min-width: 120px) and (max-width: 529px){
  .loader-1{
    position : fixed  ;
    left: 35%;
    top:20%;
    z-index: 9999;
  }
  .rgjrgou3{
    width: 65% !important;
    margin: 10px 1px 20px 10px !important;
    padding: 9px 21px !important;
  }
  .rgjrgou2{
    width: 95% !important;
    margin: 10px 1px 20px 10px !important;
    padding: 9px 21px !important;
  }
  .rgjrgou{
    width: 95% !important;
    margin: 10px 1px 50px 10px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .rgjrgou1{
    width: 90% !important;
    margin: 10px 0px 50px 19px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .site-navbar {
    width: 97% !important;
}
  }
  .ci11{
    visibility: hidden;
  }





.adjustTheSubmitButton{
    /* position: absolute;
    right : 147px;
    bottom:  7px; */
    /* background-color: #343443;  */
    border: none;
    color: black;
    padding: 1px 10px;
    border-radius:  40px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;}



    .messageStyle{
      background: skyblue;
      display: inline-block;
      padding: 10px 15px !important;
      border-radius: 10px;
      margin: 15px !important;
      overflow-wrap:anywhere;
      min-width: 40px !important;
      max-width: 550px !important;
  
  }
  /* .chatControls{
    display: flex;
    position: fixed;
    width: 34%;
    height: 50px;
    bottom: 0;
}
.chatControls div{
    padding-left: 30px  !important;
    border:2px solid #000;
    border-radius: 10px;
    width: 87%;
}

    .chatControls label{
        position: absolute;
        right : 147px;
        bottom:  -2px;
        background-color: skyblue;
        border: none;
        color: black;
        padding: 5px 10px;
        border-radius:  40px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    } */
    .wsdsd{
      padding-top: 7px !important;
      margin-left: 30px;
       background-color: transparent !important;
    }
    .rlkgeglktgkl{
      background-color: rgb(254, 189, 105);
      border-bottom-right-radius: 29px;
      border-top-right-radius: 29px;
      width: 48px !important;
      height:44px !important;
      /* margin-left: -15px; */
    margin-top: 5px;
    padding: 6px;
    color : black;
    }
    
.erkrkk:hover .dropdown123 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}
.erkrkk:hover .dropdown1234 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}

.dropdown123 {
  position: absolute;
  left: 10%;
  top: 100% !important;
  width: 80%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
}

 .dropdown123 li {
	display: block;
	margin-left: 0;
}

 .dropdown123 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown123 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}

.dropdown1234 {
  position: absolute;
  left: 40%;
  top: 100% !important;
  width: 30%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
  padding: 0px 30px;
}

 .dropdown1234 li {
	display: block;
	margin-left: 0;
}

 .dropdown1234 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown1234 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}
.fgilklk{
  padding   : 10px 30px;
  transition: all 0.3s;
  cursor: pointer !important;
}
.fgilklk1{
  padding   : 3px 10px;
  /* transition: all 0.3s;
  cursor: pointer !important; */
  border-bottom: 1px solid grey;
}
.elfkefkl{
  padding   : 5px 10px !important;
  font-size: 13px !important;
  font-weight: bold;
  margin :  0px !important;
}
.fgilklk:hover{
  background-color: #d4d4d4;
}
.dffkl{
  display:  none;
}

.rtrtrtt2323{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .rtrtrtt{
    display: none !important;
  }
  .rtrtrtt2323{
    display: block !important;
  }
  .site-block-top-search .icon {
      right: -3%;
      top: 23% !important;
       }
       .site-block-top-search input{
         padding-left: 0px !important;
       }
       .wsdsd {
        padding-top: 0px !important;
        margin-left: 14px !important;
       }
       .wsduhu{
         display:  none !important;
       }
       .dffkl{
        display:  block !important;
      }
      }

      .dfidfpfdfp{
        padding-left: 30px !important;
      }
      @media only screen and (min-width: 1400px) and (max-width: 1600px) {
        .site-block-top-search .icon{
          right: -1.6% !important;
        }
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
      }
      @media only screen and (min-width: 900px) and (max-width: 1600px) {
        .jkkukk{
          height: 360px !important;
        }
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
        .site-block-top-search .icon{
    right: -1.8% !important;
  }
}
@media only screen and (min-width: 1032px) and (max-width: 1199px) {
  .site-block-top-search .icon{
    right: -2.1% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 991px) {
  .site-block-top-search .icon{
    right: -3.4% !important;
  }
}

#myDiv1234{
  padding: 30px 10px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  top : 11%;
  display : none;
  width: 21% !important;
  right:8%;
  height : 31% ;
  background-color: white;
  box-shadow: grey 0px 2px 6px;
  /* margin-left : 30%; */
  /* border: 4px solid grey; */


}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
  #myDiv1234{
    height:320px;
    width: 200px;
    right: 9%;
    /* margin-left : 165px; */
  }}

  
@media  only screen and (min-width: 170px) and (max-width: 529px){
  
  #myDiv1234{
    height:268px !important;
    width: 300px !important;
    margin-left : 11%;
  }}


  .fjfklflkdkld{
    padding: 10px 30px;
    background: red;
    color: white;
    border: transparent;
    border-radius: 7px;
    width: 90%;
    margin : 5px 0px
  }
  .ul-li-signup > li{
   list-style: none;
  }
  .ul-li-signup >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  10px 0px;
  }
  .ul-li-signup1 > li{
   list-style: none;
  }
  .ul-li-signup1 >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  8px 0px;
  }

  /*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700');


/*

    font-family: 'Poppins', sans-serif;

    font-family: 'Dosis', sans-serif;

*/

/*------------------------------------------------------------------
    IMPORT FILES
-------------------------------------------------------------------*/

/* @import url("assets/css/all.css");
@import url(assets/css/superslides.css);
@import url(assets/css/bootstrap-select.css);
@import url(assets/css/carousel-ticker.css);
@import url(assets/css/code_animate.css);
@import url(assets/css/bootsnav.css);
@import url(assets/css/owl.carousel.min.css);
@import url(assets/css/jquery-ui.css); */
/* @import url("assets/css/nice-select.css"); */
/* @import url(assets/css/baguetteBox.min.css); */

/*------------------------------------------------------------------
    SKELETON
-------------------------------------------------------------------*/
 body {
     color: #666666;
     font-size: 15px;
     font-family: 'Dosis', sans-serif;
     line-height: 1.80857;
}
 a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}
 h1, h2, h3, h4, h5, h6 {
     letter-spacing: 0;
     font-weight: normal;
     position: relative;
     padding: 0 0 10px 0;
     font-weight: normal;
     line-height: 120% !important;
     color: #1f1f1f;
     margin: 0 
}
 h1 {
     font-size: 24px 
}
 h2 {
     font-size: 22px 
}
 h3 {
     font-size: 18px 
}
 h4 {
     font-size: 16px 
}
 h5 {
     font-size: 14px 
}
 h6 {
     font-size: 13px 
}
 h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
     color: #212121;
     text-decoration: none!important;
     opacity: 1 
}
 h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
     opacity: .8 
}
 a {
     color: #1f1f1f;
     text-decoration: none;
     outline: none;
}
 a, .btn {
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}
 .btn-custom {
     margin-top: 20px;
     background-color: transparent !important;
     border: 2px solid #ddd;
     padding: 12px 40px;
     font-size: 16px;
}
 .lead {
     font-size: 18px;
     line-height: 30px;
     color: #767676;
     margin: 0;
     padding: 0;
}
 blockquote {
     margin: 20px 0 20px;
     padding: 30px;
}
 ul, li, ol{
     list-style: none;
     margin: 0px;
     padding: 0px;
}
 button:focus{
     outline: none;
     box-shadow: none;
}
 :focus {
     outline: 0;
}
 p{
     margin: 0px;
}
 .bootstrap-select .dropdown-toggle:focus{
     outline: none !important;
}
 .form-control::-moz-placeholder {
     color: #ffffff;
     opacity: 1;
}
 .bootstrap-select .dropdown-toggle:focus{
     box-shadow: none !important 
}
/*------------------------------------------------------------------ LOADER -------------------------------------------------------------------*/
 #back-to-top {
     position: fixed;
     bottom: 40px;
     right: 40px;
     z-index: 9999;
     width: 32px;
     height: 32px;
     text-align: center;
     line-height: 5px;
     background: rgb(25 18 43);
     color: #ffffff;
     cursor: pointer;
     border: 0;
     border-radius: 0px;
     text-decoration: none;
     transition: opacity 0.2s ease-out;
     font-size: 28px;
}
/*------------------------------------------------------------------ HEADER -------------------------------------------------------------------*/
 .main-top{
     background:rgb(175, 220, 200);
     padding: 10px 0px;
}
 .custom-select-box{
     float: left;
     width: 95px;
     margin-right: 10px;
}
.custom-select-box .form-control{
     background: none;
     border: none;
}
 .custom-select-box .bootstrap-select .btn-light{
     padding: 4px;
     font-size: 14px;
     background: rgb(175, 220, 200);
     color: #fff;
     border: none;
     border-radius: 0px;
}
 .custom-select-box .bootstrap-select .btn-light span{
     padding: 2px;
     line-height: 15px;
}
 .dropdown-toggle::after{
     margin-left: -24px;
}
 .custom-select-box .dropdown-toggle::after {
     display: inline-block;
     width: 0;
     height: 0;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent;
     position: absolute;
     top: 13px;
     right: 10px;
}
 .bootstrap-select.btn-group .dropdown-toggle .filter-option{
     overflow: inherit;
}
 .bootstrap-select.btn-group .dropdown-menu{
     border: none;
     padding: 0px;
     border-radius: 0px;
}
 .right-phone-box{
     float: left;
     margin-right: 10px;
}
 .right-phone-box p{
     margin: 0px;
     color: #ffffff;
     font-size: 14px;
     line-height: 30px;
}
 .right-phone-box p a{
     color: #ffffff;
}
 .right-phone-box p a:hover{
     color: rgb(175, 220, 200);
}
 .offer-box,.slide {
     color: #FFFFFF;
     font-size: 13px;
     padding: 2px 15px;
     font-family: 'Poppins', sans-serif;
}
 .offer-box li {
     font-weight: 600;
}
 .offer-box li i{
     margin-right: 15px;
     color: rgb(175, 220, 200);
     font-size: 20px;
}
 .our-link{
     float: left;
}
 .our-link ul li{
     display: inline-block;
     border-right: 1px solid #ffffff;
     padding: 0px 10px;
     line-height: 14px;
}
 .our-link ul{
     line-height: 30px;
}
 .our-link ul li a{
     color: #ffffff;
     font-weight: 700;
     text-transform: uppercase;
     font-size: 14px;
}
 .our-link ul li a:hover{
     color: rgb(175, 220, 200);
}
 .our-link ul li:last-child{
     border: none;
}
.login-box{
    float: right;
    width: 120px;
    margin-left: 20px;
}

.login-box .form-control{
     background: none;
     border: none;
}
 .login-box .bootstrap-select .btn-light{
     padding: 4px;
     font-size: 14px;
     background: rgb(175, 220, 200);
     color: #fff;
     border: none;
     border-radius: 0px;
}
 .login-box .bootstrap-select .btn-light span{
     padding: 2px;
     line-height: 15px;
}
.login-box .dropdown-toggle::after {
     display: inline-block;
     width: 0;
     height: 0;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent;
     position: absolute;
     top: 13px;
     right: 10px;
}

.login-box .dropdown-item{
	padding: 5px 10px;
}

.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner span.text{
	font-size: 13px;
}

 .main-header{
   /* background-color:  transparent !important; */
   background-color:  rgb(25 18 43) !important;
}
 .search a{
     color: rgb(175, 220, 200);
}
 .attr-nav > ul > li > a:hover{
     color: rgb(175, 220, 200);
}
 nav.navbar.bootsnav ul.nav > li > a{
  font-family: 'Poppins', sans-serif ;

     margin: 0px;
}
/* Navbar Adjusment =========================== */
/* Navbar Atribute ------*/
 .attr-nav > ul > li > a{
     padding: 28px 15px;
}
 ul.cart-list > li.total > .btn{
     border-bottom: solid 1px #cfcfcf !important;
     color: #000000 ;
     padding: 10px 15px;
     border: none;
     font-weight: 700;
     color: #ffffff;
}
 @media (min-width: 1024px) {
    /* Navbar General ------*/
     nav.navbar ul.nav > li > a{
         padding: 30px 15px;
         font-weight: 600;
         font-family: 'Poppins', sans-serif !important;

    }
     nav.navbar .navbar-brand{
         margin-top: 0;
    }
     nav.navbar .navbar-brand{
         margin-top: 0;
    }
     nav.navbar li.dropdown ul.dropdown-menu{
         border-top: solid 5px;
    }
    /* Navbar Center ------*/
     nav.navbar-center .navbar-brand{
         margin: 0 !important;
    }
    /* Navbar Brand Top ------*/
     nav.navbar-brand-top .navbar-brand{
         margin: 10px !important;
    }
    /* Navbar Full ------*/
     nav.navbar-full .navbar-brand{
         position: relative;
         top: -15px;
    }
    /* Navbar Sidebar ------*/
     nav.navbar-sidebar ul.nav, nav.navbar-sidebar .navbar-brand{
         margin-bottom: 50px;
    }
     nav.navbar-sidebar ul.nav > li > a{
         padding: 10px 15px;
         font-weight: bold;
    }
    /* Navbar Transparent & Fixed ------*/
     nav.navbar.bootsnav.navbar-transparent.white{
         background-color: rgba(255,255,255,0.3);
         border-bottom: solid 1px #bbb;
    }
     nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark, nav.navbar.bootsnav.navbar-transparent.dark{
         background-color: rgba(0,0,0,0.3);
         border-bottom: solid 1px #555;
    }
     nav.navbar.bootsnav.navbar-transparent.white .attr-nav{
         border-left: solid 1px #bbb;
    }
     nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav, nav.navbar.bootsnav.navbar-transparent.dark .attr-nav{
         border-left: solid 1px #555;
    }
     nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a, nav.navbar.bootsnav.no-background.white ul.nav > li > a{
         color: #fff;
    }
     nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a{
         color: #eee;
    }
}
 @media (max-width: 992px) {
    /* Navbar General ------*/
     nav.navbar .navbar-brand{
         margin-top: 0;
         position: relative;
         top: -2px;
    }
     nav.navbar .navbar-brand img.logo{
      width: 78px !important;
      /* width: 93px !important; */
      /* width: 115px !important; */
      /* height: 69px;
      object-fit: unset; */
    }
     .attr-nav > ul > li > a{
         padding: 16px 15px 15px;
    }
    /* Navbar Mobile slide ------*/
     nav.navbar.navbar-mobile ul.nav > li > a{
         padding: 15px 15px;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu > li > a{
         padding-right: 15px !important;
         padding-top: 15px !important;
         padding-bottom: 15px !important;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title{
         padding-right: 30px !important;
         padding-top: 13px !important;
         padding-bottom: 13px !important;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a{
         padding-top: 13px !important;
         padding-bottom: 13px !important;
    }
    /* Navbar Full ------*/
     nav.navbar-full .navbar-brand{
         top: 0;
         padding-top: 10px;
    }
}
/* Navbar Inverse =================================*/
 nav.navbar.navbar-inverse{
     background-color: #222;
     border-bottom: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total > .btn{
     border-bottom: solid 1px #222 !important;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total .pull-right{
     color: #fff;
}
 nav.navbar.navbar-inverse.megamenu ul.dropdown-menu.megamenu-content .content ul.menu-col li a, nav.navbar.navbar-inverse ul.nav > li > a{
     color: #eee;
}
 nav.navbar.navbar-inverse ul.nav > li.dropdown > a{
     background-color: #222;
}
 nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a{
     color: #999;
}
 nav.navbar.navbar-inverse ul.nav .dropdown-menu h1, nav.navbar.navbar-inverse ul.nav .dropdown-menu h2, nav.navbar.navbar-inverse ul.nav .dropdown-menu h3, nav.navbar.navbar-inverse ul.nav .dropdown-menu h4, nav.navbar.navbar-inverse ul.nav .dropdown-menu h5, nav.navbar.navbar-inverse ul.nav .dropdown-menu h6{
     color: #fff;
}
 nav.navbar.navbar-inverse .form-control{
     background-color: #333;
     border-color: #303030;
     color: #fff;
}
 nav.navbar.navbar-inverse .attr-nav > ul > li > a{
     color: #eee;
}
 nav.navbar.navbar-inverse .attr-nav > ul > li.dropdown ul.dropdown-menu{
     background-color: #222;
     border-left: solid 1px #303030;
     border-bottom: solid 1px #303030;
     border-right: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li{
     border-bottom: solid 1px #303030;
     color: #eee;
}
 nav.navbar.navbar-inverse ul.cart-list > li img{
     border: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total{
     background-color: #333;
}
 nav.navbar.navbar-inverse .share ul > li > a{
     background-color: #555;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu{
     border-right: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a{
     border-bottom: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-content{
     border-left: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:hover, nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:focus, nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li.active > a{
     background-color: #333 !important;
}
 nav.navbar-inverse.navbar-full ul.nav > li > a{
     border:none;
}
 nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu{
     background-color: #222;
}
 nav.navbar-inverse.navbar-full .navbar-toggle{
     background-color: #222 !important;
     color: #6f6f6f;
}
 nav.navbar.bootsnav ul.nav > li > a{
     position: relative;
     font-weight: 700;
     font-size: 16px;
     color: white;
	 text-transform: uppercase;
     padding-top: 10px;
     padding-bottom: 10px;
     padding-left: 10px;
     padding-right: 10px;
     margin: 0px 10px;
     font-family: 'Poppins', sans-serif !important;
  height: 34px;
}

 nav.navbar.bootsnav ul.nav li.active > a{
     color: rgb(247, 207, 71);
}
 nav.navbar.bootsnav ul.nav li.active > a:hover{
     color: rgb(247, 207, 71);
}
 nav.navbar.bootsnav ul.nav li > a:hover{
     color: rgb(247, 207, 71);
}
 nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover{
     color: rgb(247, 207, 71);
}
 @media (min-width: 1024px) {
     nav.navbar.navbar-inverse ul.nav .dropdown-menu{
         background-color: #222 !important;
         border-left: solid 1px #303030 !important;
         border-bottom: solid 1px #303030 !important;
         border-right: solid 1px #303030 !important;
    }
     nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a{
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu{
         border-left: solid 1px #303030;
         border-right: solid 1px #303030;
    }
     nav.navbar.navbar-inverse.navbar-transparent.dark{
         background-color: rgba(0,0,0,0.3);
         border-bottom: solid 1px #999;
    }
     nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav{
         border-left: solid 1px #999;
    }
     nav.navbar.navbar-inverse.no-background.white .attr-nav > ul > li > a, nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav > li > a, nav.navbar.navbar-inverse.no-background.white ul.nav > li > a{
         color: #fff;
    }
     nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a, nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a{
         color: rgb(247, 207, 71);
    }
}
 @media (max-width: 992px) {
     nav.navbar.navbar-inverse .navbar-toggle{
         color: #eee;
         background-color: #222 !important;
    }
     nav.navbar.navbar-inverse .navbar-nav > li > a{
         border-top: solid 1px #303030;
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu > li > a{
         color: #999;
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title{
         border-bottom: solid 1px #303030;
         color: #eee;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul > li > a{
         border-bottom: solid 1px #303030;
         color: #999 !important;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu.on:last-child .title{
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul{
         border-top: solid 1px #303030;
    }
     nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse{
         background-color: #222;
    }
}
 @media (max-width: 767px) {
     nav.navbar.navbar-inverse.navbar-mobile ul.nav{
         border-top: solid 1px #222;
    }
}
 .arrow::before{
     font-family: 'FontAwesome';
     content: "\f0d7";
     margin-left: 5px;
     margin-top: 2px;
     border: none;
     display: inline-block;
     vertical-align: inherit;
     position: absolute;
     right: 10px;
     top: 10px;
}
 .dropdown-toggle::after{
     display: none;
}
 nav.navbar.bootsnav ul.navbar-nav li.dropdown ul.dropdown-menu li a{
   color : white !important;
     display: block;
	 padding: 6px 25px;
}

 nav.navbar.bootsnav ul.navbar-nav li.dropdown ul.dropdown-menu li a:hover{
	 
 }
 nav.navbar.bootsnav li.dropdown ul.dropdown-menu{
   background-color: rgb(25, 18 ,43);
     left: auto;
}
.custom-select-box .dropdown-item{
	padding: 5px 10px;
}
 .btn-cart{
}
 .cart-list h6 a{
     font-size: 16px;
     font-weight: 700;
}
 .cart-list h6 a:hover{
     color: rgb(247, 207, 71);
}
 ul.cart-list p .price{
     font-weight: normal;
}
.side-menu p{
	display: inline-block;
}
 .col-menu .title{
     font-size: 20px;
     font-weight: 700;
     text-transform: uppercase;
}
 .hvr-hover{
     display: inline-block;
     vertical-align: middle;
     -webkit-transform: perspective(1px) translateZ(0);
     transform: perspective(1px) translateZ(0);
     box-shadow: 0 0 1px rgba(0, 0, 0, 0);
     position: relative;
     background: rgb(175, 220, 200);
     -webkit-transition-property: color;
     transition-property: color;
     -webkit-transition-duration: 0.3s;
     transition-duration: 0.3s;
     border-radius: 0;
     box-shadow: none;
}
 .hvr-hover::after {
    content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000000;
	border-radius: 100%;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
 .hvr-hover:hover::after{
     -webkit-transform: scale(2);
	 transform: scale(2);
     color: #ffffff;
}
 .hvr-hover{
	 overflow: hidden;
}
 ul.cart-list > li.total > .btn:hover{
     color: #ffffff;
}
 .cart-box{
     margin-top: 40px;
     background: #ffffff;
}
 .main-header.fixed-menu {
     position: fixed;
     visibility: hidden;
     left: 0px;
     top: 0px;
     width: 100%;
     padding: 0px 0px;
     background: #ffffff;
     z-index: 0;
     transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     z-index: 999;
     opacity: 1;
     visibility: visible;
     -ms-animation-name: fadeInDown;
     -moz-animation-name: fadeInDown;
     -op-animation-name: fadeInDown;
     -webkit-animation-name: fadeInDown;
     animation-name: fadeInDown;
     -ms-animation-duration: 500ms;
     -moz-animation-duration: 500ms;
     -op-animation-duration: 500ms;
     -webkit-animation-duration: 500ms;
     animation-duration: 500ms;
     -ms-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
     -op-animation-timing-function: linear;
     -webkit-animation-timing-function: linear;
     animation-timing-function: linear;
     -ms-animation-iteration-count: 1;
     -moz-animation-iteration-count: 1;
     -op-animation-iteration-count: 1;
     -webkit-animation-iteration-count: 1;
     animation-iteration-count: 1;
}
 .main-header.fixed-menu {
     padding: 0px;
     box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
     border-radius: 0;
}
 .top-search .input-group-addon{
     line-height: 40px;
}
 .top-search input.form-control{
     color: #ffffff;
}
/*------------------------------------------------------------------ Slider -------------------------------------------------------------------*/
 .cover-slides{
     height: 100vh;
}
 .slides-navigation a {
     background: rgb(175, 220, 200);
     position: absolute;
     height: 70px;
     width: 70px;
     top: 50%;
     font-size: 20px;
     display: block;
     color: #fff;
     line-height: 90px;
     text-align: center;
     transition: all .3s ease-in-out;
}
 .slides-navigation a i{
     font-size: 40px;
}
 .slides-navigation a:hover {
     background: #000000;
}
 .cover-slides .container{
     height: 100%;
     position: relative;
     z-index: 2;
}
 .cover-slides .container > .row {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
}
 .cover-slides .container > .row {
     height: 100%;
}
 .overlay-background {
     background: #333;
     position: absolute;
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     opacity: 0.5;
}
 .cover-slides h1{
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     font-size: 64px;
     color: #fff;
}
 .erekj{
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     font-size: 64px;
     color: #fff;
}
 .cover-slides p{
     font-size: 18px;
     color: #fff;
     padding-bottom: 30px;
}
 .dsflk{
     font-size: 18px;
     color: #fff;
     padding-bottom: 30px;
}
 .slides-pagination a{
     border: 2px solid #ffffff;
	 border-radius: 6px;
}
 .slides-pagination a.current{
     background: rgb(175, 220, 200);
     border: 2px solid rgb(175, 220, 200);
}
 .cover-slides p a{
     font-size: 24px;
     color: #ffffff;
     border: none;
     text-transform: uppercase;
     padding: 10px 20px;
}
 .sdklkl{
     font-size: 24px;
     color: #ffffff;
     border: none;
     text-transform: uppercase;
     padding: 10px 20px;
}
.slide {
  padding: 0px !important;
  max-height: 738px !important;
}
.efeeee{
  /* height: 980px !important; */
  height: 630px !important;
  /* height: 750px !important; */
  width: 100%;
}
.efeeee1{
  height: 500px !important;
  width: 100%;
}
.jkjkjkkjjk{
  height: 650px;
  padding : 50px 0px;
  background: url("assets/images/LOGIN 123.jpg");
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    
    /* background-size: contain;
    background-position: center; */
  }
  .jkjkjkkjjk1{
    height: 650px;
    padding : 50px 0px;
    background: url("assets/images/LOGIN 123.jpg");
    /* background: url(assets/images/LOGIN.jpg); */
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    
    /* background-size: contain;
    background-position: center; */
  }
  .jkjjjljl{
    background: url(assets/images/ban-35.jpeg);
  /* background-size: contain;
    background-position: center; */
}
/* .carousel-indicators{
  display: none !important;
} */
/*------------------------------------------------------------------ Categories Shop -------------------------------------------------------------------*/
 .categories-shop{
     padding: 70px 0px;
}
 .shop-cat-box{
     margin-bottom: 30px;
     position: relative;
     padding: 3px;
     overflow: hidden;
     border: 0px solid #000000;
	 box-shadow: 9px 9px 30px 0px rgba(0, 0, 0, 0.3);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease-in-out 0s;
}
 .shop-cat-box img{
     margin: 23px 0 0 -15px;
     max-width: none;
     width: -webkit-calc(100% + 10px);
     width: calc(100% + 10px);
     opacity: 0.9;
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
     -webkit-transform: translate3d(10px,10px,0);
     transform: translate3d(10px,10px,0);
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
}
 .shop-cat-box:hover img{
     opacity: 0.6;
     -webkit-transform: translate3d(0,0,0);
     transform: translate3d(0,0,0);
}
 .shop-cat-box a{
     position: absolute;
     z-index: 2;
     bottom: 0px;
     left: 0px;
     right: 0;
     margin: 0 auto;
     text-align: center;
     border: none;
     color: #ffffff;
     font-size: 18px;
     font-weight: 700;
     padding: 6px 0px;
}

.box-add-products{
	/* padding: 70px 0px; */
	background-color: #f4f4f4;
  /* background: rgb; */
}

.offer-box{
	position: relative;
	overflow: hidden;
}

.offer-box-products{
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease-in-out 0s;
}

.offer-box-products:hover{
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
	box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------ Products -------------------------------------------------------------------*/
 .title-all{
     margin-bottom: 30px;
}
 .title-all h1{
     font-size: 32px;
     font-weight: 700;
     color: #000000;
}
 .title-all p{
     color: #999999;
     font-size: 16px;
}
 .products-box{
     padding: 70px 0px;
}
 .special-menu{
     margin-bottom: 40px;
}
 .filter-button-group{
     display: inline-block;
}
 .filter-button-group button{
     background: rgb(175, 220, 200);
     color: #ffffff;
     border: none;
     cursor: pointer;
     padding: 5px 30px;
     font-size: 18px;
}
 .filter-button-group button.active{
     background: #000000;
}
 .filter-button-group button{
}
 .products-single {
     overflow: hidden;
     position: relative;
     margin-bottom: 30px;
}
 .products-single .box-img-hover{
     overflow: hidden;
     position: relative;
}
 .box-img-hover img{
     margin: 0 auto;
     text-align: center;
     display: block;
}
 .type-lb{
     position: absolute;
     top: 0px;
     right: 0px;
     z-index:8;
}
 .type-lb .sale{
     background: rgb(175, 220, 200);
     color: #ffffff;
     padding: 2px 10px;
     font-weight: 700;
     text-transform: uppercase;
}
 .type-lb .new{
     background: #000000;
     color: #ffffff;
     padding: 2px 10px;
     font-weight: 700;
     text-transform: uppercase;
}
 .why-text{
     /* background: #f5f5f5; */
     padding: 0px 15px;
}
 .why-text h4{
     font-size: 16px;
     font-weight: 700;
     padding-bottom: 15px;
}
 .why-text h5{
     font-size: 18px;
     font-family: 'Poppins', sans-serif;
     padding: 4px;
	 display: inline-block;
	 background: rgb(175, 220, 200);
	 color: #ffffff;
     font-weight: 600;
}
 .mask-icon{
     width: 100%;
     height: 100%;
     position: absolute;
     overflow: hidden;
     top: 0;
     left: 0;
}
 .mask-icon ul{
     display: inline-block;
	 position: absolute;
	 bottom: 0;
	 right: 0;
}
 .mask-icon ul li{
     background: rgb(175, 220, 200);
}
 .mask-icon ul li a{
     color: #ffffff;
     padding: 5px 10px;
     display: block;
}
 .mask-icon a.cart{
     background: rgb(175, 220, 200);
     position: absolute;
     bottom: 0;
     left: 0px;
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
}
 .mask-icon a.cart:hover{
     background: #000000;
     color: #ffffff;
}
 .mask-icon ul li a:hover{
     background: #000000;
     color: #ffffff;
}
 .products-single .mask-icon{
     background: rgba(1,1,1, 0.5);
     top: -100%;
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     opacity: 0;
     -webkit-transition: all 0.3s ease-out 0.5s;
     -moz-transition: all 0.3s ease-out 0.5s;
     -o-transition: all 0.3s ease-out 0.5s;
     -ms-transition: all 0.3s ease-out 0.5s;
     transition: all 0.3s ease-out 0.5s;
}
 .products-single:hover .mask-icon{
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
     filter: alpha(opacity=100);
     opacity: 1;
     top: 0px;
     -webkit-transition-delay: 0s;
     -moz-transition-delay: 0s;
     -o-transition-delay: 0s;
     -ms-transition-delay: 0s;
     transition-delay: 0s;
     -webkit-animation: bounceY 0.9s linear;
     -moz-animation: bounceY 0.9s linear;
     -ms-animation: bounceY 0.9s linear;
     animation: bounceY 0.9s linear;
}
 @keyframes bounceY {
     0% {
         transform: translateY(-205px);
    }
     40% {
         transform: translateY(-100px);
    }
     65% {
         transform: translateY(-52px);
    }
     82% {
         transform: translateY(-25px);
    }
     92% {
         transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         transform: translateY(0px);
    }
}
 @-moz-keyframes bounceY {
     0% {
         -moz-transform: translateY(-205px);
    }
     40% {
         -moz-transform: translateY(-100px);
    }
     65% {
         -moz-transform: translateY(-52px);
    }
     82% {
         -moz-transform: translateY(-25px);
    }
     92% {
         -moz-transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         -moz-transform: translateY(0px);
    }
}
 @-webkit-keyframes bounceY {
     0% {
         -webkit-transform: translateY(-205px);
    }
     40% {
         -webkit-transform: translateY(-100px);
    }
     65% {
         -webkit-transform: translateY(-52px);
    }
     82% {
         -webkit-transform: translateY(-25px);
    }
     92% {
         -webkit-transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         -webkit-transform: translateY(0px);
    }
}




/*------------------------------------------------------------------ Blog -------------------------------------------------------------------*/
 .latest-blog{
     padding: 70px 0px;
     background: #f5f5f5;
}
 .blog-box{
     -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
     box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
     background: #ffffff;
     margin-bottom: 30px;
}
 .blog-content{
     position: relative;
}
 .title-blog{
     padding: 40px 30px 40px 30px;
}
 .title-blog h3{
     font-size: 20px;
     font-weight: 700;
     color: #000000;
}
 .title-blog p{
     margin: 0px;
}
 .option-blog {
     position: absolute;
     bottom: 0px;
     left: 0px;
	 right: 0px;
	 margin: 0 auto;
	 text-align: center;
}
 .option-blog li{
	 display: inline-block;
 }
 .option-blog li a{
     background: #000000;
     display: inline-block;
     font-size: 18px;
     color: #ffffff;
     width: 34px;
     height: 34px;
     text-align: center;
     line-height: 34px;
}
 .option-blog li a:hover{
     background: rgb(175, 220, 200);
     color: #ffffff;
}
 .tooltip-inner {
     background-color: rgb(175, 220, 200);
}
 .tooltip.bs-tooltip-right .arrow:before {
     border-right-color: rgb(175, 220, 200) !important;
     padding-top: 0px !important;
     top: 0px !important;
}
 .tooltip.bs-tooltip-left .arrow:before {
     border-left-color: rgb(175, 220, 200) !important;
	 padding-top: 0px !important;
	 padding-right: 5px !important;
     top: 0px !important;
}
 .tooltip.bs-tooltip-bottom .arrow:before {
     border-right-color: rgb(175, 220, 200) !important;
}
 .tooltip.bs-tooltip-top .arrow:before {
     border-top-color: rgb(175, 220, 200) !important;
	 padding-top: 0px !important;
     top: 0px !important;
}
/*------------------------------------------------------------------ Instagram Feed -------------------------------------------------------------------*/
 .instagram-box{
     padding: 70px 0px;
     /* background: url("images/ins-bg.jpg") no-repeat center center; */
     background-size: auto auto;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -ms-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     position: relative;
}
 .instagram-box::before{
     background: rgba(0,0,0,0.9);
     width: 100%;
     height: 100%;
     left: 0px;
     top: 0px;
     content: "" ;
     position: absolute;
     z-index: 0;
}
 .main-instagram.owl-carousel .owl-nav button.owl-prev{
     background: #000000;
     position: absolute;
     z-index: 1;
     display: block;
     height: 100%;
     width: 50px;
     line-height: 0px;
     font-size: 24px;
     cursor: pointer;
     color: #ffffff;
     top: 0;
     padding: 0;
     margin-top: 0;
     opacity: 1;
     left: 0px;
     -webkit-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
}
 .main-instagram.owl-carousel .owl-nav button.owl-next{
     background: #000000;
     position: absolute;
     z-index: 1;
     display: block;
     height: 100%;
     width: 50px;
     line-height: 0px;
     font-size: 24px;
     cursor: pointer;
     color: #ffffff;
     top: 0;
     padding: 0;
     margin-top: 0;
     opacity: 1;
     right: 0px;
     -webkit-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
}
 .main-instagram.owl-carousel .owl-nav button.owl-next:hover, .main-instagram.owl-carousel .owl-nav button.owl-prev:hover{
     background: rgb(175, 220, 200);
}
 .ins-inner-box{
     position: relative;
}
 .hov-in{
     opacity: 0;
     background: rgba(211,59,51, 0.5);
     bottom: -100%;
     position: absolute;
     width: 100%;
     height: 100%;
     -webkit-transition: all 0.3s ease-out 0.5s;
     -moz-transition: all 0.3s ease-out 0.5s;
     -o-transition: all 0.3s ease-out 0.5s;
     -ms-transition: all 0.3s ease-out 0.5s;
     transition: all 0.3s ease-out 0.5s;
     text-align: center;
     display: table;
}
 .hov-in a{
     display: table-cell;
     vertical-align: middle;
     height: 100%;
}
 .hov-in i{
     color: #000000;
     font-size: 48px;
     position: relative;
     z-index: 2;
}
 .hov-in a i:hover{
     color: #ffffff;
}
 .ins-inner-box:hover .hov-in{
     bottom: 0;
     opacity: 1;
}
/*------------------------------------------------------------------ Footer Main -------------------------------------------------------------------*/
 .footer-main{
     padding: 70px 0px;
     background: rgb(25 18 43);
}
.footer-main hr{
	border-top: 1px solid rgba(255,255,255,.6);
}
.footer-top-box{
	margin-bottom: 30px;
}
.footer-top-box h3{
	color: #ffffff;
	position: relative;
	font-size: 20px;
}
.footer-top-box h3::before {
    border-bottom: 2px solid white;
    content: "";
    height: 3px;
    width: 50%;
    position: absolute;
    bottom: 3px;
    left: 0px;
}
.list-time{
	margin-top: 15px;
}
.footer-top-box .list-time li{
	color: #ffffff;
	font-size: 16px;
	display: block;
	float: none;
}

.newsletter-box{
	margin-top: 15px;
	position: relative;
}
.newsletter-box input{
	height: 40px;
	border: none;
	padding: 0 10px;
	background: transparent;
	border-radius: 0;
	background: white !important;
	padding: 0 20px;
	width: 100%;
}
.newsletter-box i{
	position: absolute;
	right: 15px;
	top: 10px;
	font-size: 20px;
}
.newsletter-box button{	
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	
}
 .footer-top-box p{
	 padding-bottom: 15px;
	 color: #cccccc;
 }

 .footer-top-box ul{
     display: inline-block;
}
 .footer-top-box ul li {
     float: left;
     margin-right: 5px;
}
 .footer-top-box ul li a {
     color: #ffffff;
     display: inline-block;
     width: 36px;
     height: 36px;
     border: 2px solid #ffffff;
     text-align: center;
     line-height: 32px;
}
 .footer-top-box ul li a:hover {
     color: rgb(175, 220, 200);
     border-color: rgb(175, 220, 200);
}
.footer-widget, .footer-link, .footer-link-contact{
	margin-top: 15px;
}
 .footer-widget h4 {
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 20px;
     position: relative;
     font-weight: 700;
}
 .footer-widget h4::before {
     border-bottom: 3px solid #F7CF47;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-widget p {
     color: #cccccc;
     font-weight: 400;
     font-size: 14px;
     padding-bottom: 20px;
}


 .footer-link h4{
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 20px;
     position: relative;
     font-weight: 700;
}
 .footer-link h4::before {
     border-bottom: 3px solid #F7CF47;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-link ul li {
     margin-right: 5px;
}
 .footer-link ul li a {
     color: #ffffff;
     text-align: left;
     display: block;
     line-height: 32px;
     position: relative;
     padding-left: 15px;
     transition: all .3s ease-in-out;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
}
 /* .footer-link ul li a::before {
     font-family: FontAwesome;
     content: "\f105";
     position: absolute;
     left: 5px;
     line-height: 35px;
     font-size: 16px;
     transition: all .3s ease-in-out;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
} */
 .footer-link ul li a:hover::before {
     left: 5px;
}
 .footer-link ul li a:hover {
     color: rgb(175, 220, 200);
     border-color: rgb(175, 220, 200);
     padding-left: 20px;
}
 .footer-link-contact h4{
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 20px;
     position: relative;
     font-weight: 700;
}
 .footer-link-contact h4::before {
     border-bottom: 3px solid #F7CF47;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-link-contact ul li{
     margin-bottom: 12px;
}
 .footer-link-contact ul li i{
     position: absolute;
     left: 0;
     top: 5px;
     padding-right: 6px;
}
 .footer-link-contact ul li p{
     padding-left: 25px;
     color: #cccccc;
     position: relative;
}
 .footer-link-contact ul li p{
     font-size: 16px;
     color: #cccccc;
     font-weight: 300;
     padding-right: 16px;
     line-height: 24px;
}
 .footer-link-contact ul li p a{
     color: #cccccc;
}
 .footer-link-contact ul li p a:hover{
     color: rgb(175, 220, 200);
}
/*------------------------------------------------------------------ Copyright -------------------------------------------------------------------*/
 .footer-copyright{
   border-top: 2px solid black !important;
     background: rgb(25, 18 ,43);
     padding: 20px 0px;
     position: relative;
}
 .footer-copyright p {
     text-align: center;
     color: #ffffff;
     font-size: 16px;
}
 .footer-copyright p a{
     color: #ffffff;
}
 .footer-copyright p a:hover{
     color: rgb(175, 220, 200);
}
/*------------------------------------------------------------------ All Pages -------------------------------------------------------------------*/
 .all-title-box{
     /* background: url("images/all-bg-title.jpg") no-repeat center center; */
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -ms-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     text-align: center;
     background-attachment: fixed;
     padding: 70px 0px;
     position: relative;
}
 .all-title-box::before{
     background: rgba(0,0,0,0.6);
     content: "";
     position: absolute;
     z-index: 0;
     width: 100%;
     height: 100%;
     left: 0px;
     top: 0px;
}
 .all-title-box h2{
     font-size: 28px;
     font-weight: 700;
     color: #ffffff;
	 float: left;
     padding: 10px 0px;
}
 .all-title-box .breadcrumb{
     background: rgb(175, 220, 200);
     margin: 0px;
     display: inline-block;
     border-radius: 0px;
	 float: right;
}
 .all-title-box .breadcrumb li{
     display: inline-block;
     color: #000000;
     font-size: 16px;
}
 .all-title-box .breadcrumb li a{
     color: #ffffff;
     font-size: 16px;
}
 .all-title-box .breadcrumb-item + .breadcrumb-item::before{
     color: #000000;
}
 .about-box-main{
     padding: 70px 0px;
}
 .noo-sh-title{
     font-size: 28px;
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
	 margin-bottom: 30px;
}
.noo-sh-title-top{
     font-size: 28px;
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
	 margin-bottom: 0px;
}
 .about-box-main p{
     padding-bottom: 20px;
}
 .service-block-inner {
     padding: 15px 20px;
     position: relative;
     margin-bottom: 30px;
}

.about-box-main a{
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	border: none;
}

.about-box-main a.btn{
	padding: 12px 15px;
}

 .service-block-inner::before {
     content: "";
     width: 100%;
     height: 5px;
     border-radius: 0px;
     background: #000000;
     position: absolute;
     top: 0;
     left: 0;
     transition: all 0.5s ease 0s;
}
 .service-block-inner::after {
     content: "";
     width: 100%;
     height: 0;
     border-radius: 0px;
     background: rgb(175, 220, 200);
     position: absolute;
     top: 0;
     left: 0;
	 z-index: -1;
     transition: all 0.5s ease 0s;
}
 .service-block-inner h3 {
     font-size: 24px;
     text-transform: uppercase;
     font-weight: 600;
}
 .service-block-inner p {
     margin: 0px;
     font-size: 16px;
     font-weight: 300;
     padding-bottom: 0px;
}
 .service-block-inner:hover::after {
     height: 100%;
}

.service-block-inner:hover h3{
	color: #ffffff;
	transition: all 0.5s ease 0s;
}

.service-block-inner:hover p{
	color: #ffffff;
	transition: all 0.5s ease 0s;
}

 .our-team{
     box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
     overflow: hidden;
     position: relative;
     transition: all 0.3s ease 0s;
}
 .our-team:hover{
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
 .our-team img{
     width: 100%;
     height: auto;
     transition: all 0.3s ease-in-out 0s;
}
 .our-team:hover img{
     opacity: 0.5;
}
 .our-team .team-content{
     color: #000;
     opacity: 1;
     position: absolute;
     bottom: 0px;
     left: 0px;
	 background: #ffffff;
	 padding-left: 55px;
	 width: 100%;
     transition: all 0.3s ease 0s;
}
 .our-team .title{
     display: block;
     font-size: 20px;
     font-weight: 700;
     margin: 0 0 7px 0;
}
 .our-team .post{
     display: block;
     font-size: 15px;
}
 .our-team .social{
     list-style: none;
     padding: 0;
     margin: 0;
     width: 35px;
     text-align: center;
     opacity: 0;
     position: absolute;
     bottom: 35px;
	 right: 0px;
     transition: all 0.3s ease 0s;
}
 .our-team:hover .social{
     opacity: 1;
}
 .our-team .social li{
     display: block;
}
 .our-team .social li a{
     display: inline-block;
     width: 35px;
     height: 35px;
     line-height: 35px;
     background: #000000;
     font-size: 17px;
     color: #fff;
     opacity: 0.9;
     position: relative;
     transform: translate(35px, -35px);
     transition: all 0.3s ease-in-out 0s 
}
 .our-team .social li a:hover{
     width: 35px;
     background: rgb(175, 220, 200);
     transition-delay: 0s;
}
 .our-team .icon{
     width: 35px;
     height: 35px;
     line-height: 35px;
     background: rgb(175, 220, 200);
     text-align: center;
     color: #fff;
     position: absolute;
     bottom: 0;
}
 .team-description{
     padding: 20px 0px;
}
 .team-description p{
     font-size: 14px;
     margin: 0px;
}
 .hover-team:hover .social{
     opacity: 1;
}
 .hover-team:hover .social li:nth-child(1) a{
     transition-delay: 0.3s;
}
 .hover-team:hover .social li:nth-child(2) a{
     transition-delay: 0.2s;
}
 .hover-team:hover .social li:nth-child(3) a{
     transition-delay: 0.1s;
}
 .hover-team:hover .social li:nth-child(4) a{
     transition-delay: 0.0s;
}
 .hover-team:hover .social li a{
     transform: translate(0, 0) 
}
 .hover-team:hover img{
     opacity: 0.5;
}
 .hover-team .team-content .title{
     border-bottom: 2px solid rgb(175, 220, 200);
}
 .shop-box-inner{
     padding: 70px 0px;
}
 .search-product {
     position: relative;
}
 .search-product input[type="text"] {
     background: #333333;
     border: 0;
     box-shadow: none;
     border-radius: 0;
     color: #ffffff;
     height: 55px;
     font-weight: 300;
     font-size: 16px;
     margin-bottom: 15px;
     padding: 0 20px;
     -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
     transition: all .5s ease;
     width: 100%;
     outline: 0;
}
 .search-product .form-control::-moz-placeholder {
     color: #ffffff;
     opacity: 1;
}
 .search-product button {
     background: #000000;
     color: #ffffff;
     font-size: 18px;
     position: absolute;
     right: 0px;
     padding: 12px 15px;
     top: 0;
     line-height: 27px;
     border: none;
     cursor: pointer;
     height: 100%;
}
 .search-product button:hover{
     background: rgb(175, 220, 200);
}
 .filter-sidebar-left{
     margin-bottom: 20px;
}
 .title-left{
     font-size: 16px;
     border-bottom: 3px solid #000000;
     margin-bottom: 20px;
}
 .title-left h3{
     font-weight: 700;
}
 .list-group-item {
     border: none;
     padding: 5px 20px;
     font-size: 14px;
}
 .text-muted {
     padding: 10px 0px;
}
 .list-group-item[data-toggle="collapse"]::after {
     width: 0;
     height: 0;
     position: absolute;
     top: calc(50% - 12px);
     right: 10px;
     content: '';
     -webkit-transition: top .2s,-webkit-transform .2s;
     transition: top .2s,-webkit-transform .2s;
     transition: transform .2s,top .2s;
     transition: transform .2s,top .2s,-webkit-transform .2s;
     font-family: FontAwesome;
     content: "\f105";
}
 .list-group-tree .list-group-collapse .list-group {
     margin-left: 25px;
     border-left: 1px solid #ced4da;
}
 .list-group-tree .list-group-item.active {
     color: rgb(175, 220, 200);
     background-color: #fff;
     font-weight: 700;
}
 .list-group-tree .list-group-collapse .list-group > .list-group-item::before {
     position: absolute;
     top: 14px;
     left: 0;
     content: '';
     width: 8px;
     height: 1px;
     background-color: #ced4da;
}
 .list-group-tree .list-group-item:hover {
     color: rgb(175, 220, 200);
     background-color: #fff;
     outline: 0;
     font-weight: 700;
}
 .filter-price-left{
     margin-bottom: 20px;
}
 #slider-range .ui-slider-handle {
     background-color: rgb(175, 220, 200);
     border: 2px solid #fff;
     border-radius: 50%;
     cursor: pointer;
     height: 21px;
     top: -6px;
     transition: none 0s ease 0s;
     width: 21px;
     box-shadow: 0px 0px 6.65px 0.35px rgba(0,0,0,0.15);
}
 #slider-range .ui-slider-range {
     background-color: rgb(175, 220, 200);
     border-radius: 0;
}
 #slider-range {
     background: #000000;
     border: medium none;
     border-radius: 50px;
     float: left;
     height: 10px;
     margin-top: 14px;
     width: 100%;
}
 .price-box-slider p{
     clear: both;
     margin-top: 20px;
     display: inline-block;
     width: 100%;
}
 .price-box-slider p input{
     margin-top: 5px;
}
 .price-box-slider p button{
     border: none;
     color: #ffffff;
     float: right;
}
 .brand-box {
     display: inline-block;
     width: 100%;
     height: 259px;
     position: relative;
}
 .product-item-filter{
     border-bottom: 1px solid #000000;
     border-top: 1px solid #000000;
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -webkit-box-pack: justify;
     -ms-flex-pack: justify;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     padding: 5px 0;
}
 .nice-select.wide{
     width: 75%;
}
 .product-item-filter .toolbar-sorter-right span{
     line-height: 42px;
     padding-right: 15px;
     float: left;
}
 .product-item-filter .toolbar-sorter-right{
     width: 65%;
}
 .toolbar-sorter-right{
     float: left;
}
 .toolbar-sorter-right .bootstrap-select.form-control:not([class*="col-"]){
     width: 77%;
     float: right;
}
 .toolbar-sorter-right .bootstrap-select.btn-group > .dropdown-toggle{
     padding: 0px;
     border-radius: 0px;
     border: none;
}
 .toolbar-sorter-right .bootstrap-select.btn-group .dropdown-toggle .filter-option{
     padding-left: 15px;
}
 .toolbar-sorter-right .btn-light{
     background: #000000;
     color: #ffffff;
}
 .toolbar-sorter-right .btn-light:hover{
     background: rgb(175, 220, 200);
     border-radius: 0px;
     border: none;
}
 .toolbar-sorter-right .show > .btn-light.dropdown-toggle{
     background-color: rgb(175, 220, 200);
}
 .toolbar-sorter-right .bootstrap-select .dropdown-toggle:focus{
     background: rgb(175, 220, 200);
}
 .toolbar-sorter-right .dropup .dropdown-toggle::after{
     position: absolute;
     right: 15px;
     top: 18px;
}
 .product-item-filter p{
     float: right;
     line-height: 42px;
}
 .product-item-filter .nav-tabs{
     border: none;
     float: right;
}
 .nav > li {
     position: relative;
     display: inline-block;
     vertical-align: middle;
}
 .product-item-filter li .nav-link {
     border: none;
     border-radius: 0px;
     color: white;
     font-size: 18px;
     /* padding: 4px 12px; */
}
 .product-item-filter li .nav-link.active {
     /* background: rgb(175, 220, 200); */
     border: none;
     color: #ffffff;
}
 .product-item-filter li .nav-link:hover {
     /* background: #000000; */
     border: none;
     color: #ffffff;
}
 .product-categori{
     margin-bottom: 30px;
}
 .product-categorie-box{
     margin-top: 20px;
}
 .tab-content, .tab-pane{
     width: 100%;
}
 .why-text.full-width h4 {
     font-size: 24px;
     font-weight: 700;
     padding-bottom: 15px;
}
 .why-text.full-width h5 del{
     font-size: 13px;
     color: #666;
}
 .why-text.full-width h5{
     color: #000000;
     font-weight: 700;
}
 .why-text.full-width p{
     padding-bottom: 20px;
}
 .why-text.full-width a{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .list-view-box{
     margin-bottom: 30px;
}
 .list-view-box:hover .mask-icon{
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
     filter: alpha(opacity=100);
     opacity: 1;
     top: 0px;
     -webkit-transition-delay: 0s;
     -moz-transition-delay: 0s;
     -o-transition-delay: 0s;
     -ms-transition-delay: 0s;
     transition-delay: 0s;
     -webkit-animation: bounceY 0.9s linear;
     -moz-animation: bounceY 0.9s linear;
     -ms-animation: bounceY 0.9s linear;
     animation: bounceY 0.9s linear;
}
 .cart-box-main{
     padding: 70px 0px;
}
 .table-main table thead{
     background: rgb(175, 220, 200);
     color: #ffffff;
}
 .table-main .table thead th{
     font-size: 18px;
}
 .table-main table td.thumbnail-img{
     width: 120px;
}
 .table-main table td{
     vertical-align: middle;
     font-size: 16px;
}
 .quantity-box input{
     width: 60px;
     border: 2px solid #000000;
     text-align: center;
}
 .quantity-box input:focus{
     border-color: rgb(175, 220, 200);
}
 .name-pr a{
     font-weight: 700;
     font-size: 18px;
     color: #000000;
}
 .remove-pr{
     text-align: center;
}
 .coupon-box .input-group .form-control {
     min-height: 50px;
     border-radius: 0px;
     font-weight: 400;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
}
 .coupon-box .input-group .form-control::-moz-placeholder{
     color: #000000;
}
 .coupon-box .input-group .input-group-append .btn-theme {
     background: #000000;
     color: #ffffff;
     border: none;
     border-radius: 0px;
     font-size: 16px;
     padding: 0px 20px;
}
 .coupon-box .input-group .input-group-append .btn-theme:hover {
     background: rgb(175, 220, 200);
}
 .update-box input[type="submit"]{
     background: #000000;
     border: medium none;
     border-radius: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #fff;
     display: inline-block;
     float: right;
     cursor: pointer;
     font-size: 16px;
     font-weight: 500;
     height: 50px;
     line-height: 40px;
     margin-right: 15px;
     padding: 0 20px;
     text-shadow: none;
     text-transform: uppercase;
     -webkit-transition: all 0.3s ease 0s;
     transition: all 0.3s ease 0s;
     white-space: nowrap;
     width: inherit;
}
 .update-box input[type="submit"]:hover{
     background: rgb(175, 220, 200);
}
 .order-box h3{
     font-size: 16px;
     color: #222222;
     font-weight: 700;
}
 .order-box h4 {
     font-size: 16px;
     padding: 0px;
     line-height: 35px !important;
}
 .order-box .font-weight-bold{
     font-size: 18px;
}
 .gr-total h5 {
     font-weight: 700;
     color: rgb(175, 220, 200);
     font-size: 18px;
     margin: 0px;
     padding: 0px;
     line-height: 35px !important;
}
 .gr-total .h5{
     margin: 0px;
     font-weight: 700;
     line-height: 35px;
}
 .my-account-box-main{
     padding: 70px 0px;
}
 .shopping-box a{
     font-size: 18px;
     color: #ffffff;
     border: none;
     padding: 10px 20px;
}
 .payment-icon {
     display: inline-block;
     padding: 10px 0px;
}
 .payment-icon ul li {
     width: 20%;
     float: left;
}
 .needs-validation label {
     font-size: 16px;
     margin-bottom: 0px;
     line-height: 24px;
}
 .needs-validation .form-control {
     min-height: 40px;
     border-radius: 0px;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
     font-size: 14px;
}
 .needs-validation .form-control:focus {
     border: 1px solid rgb(175, 220, 200) !important;
}
 .review-form-box .form-control{
     min-height: 40px;
     border-radius: 0px;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
     font-size: 14px;
}
 .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
     background-color: rgb(175, 220, 200);
     box-shadow: none;
}
 .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
     background-color: rgb(175, 220, 200);
     box-shadow: none;
}
 .new-account-login h5 {
     font-size: 18px;
     color: #111111;
     font-weight: 600;
}
 .new-account-login h5 a:hover{
     color: rgb(175, 220, 200);
}
 .review-form-box button{
     padding: 10px 20px;
     color: #ffffff;
     font-size: 18px;
     border: none;
}
 .wide.w-100{
     min-height: 40px;
     border: 1px solid #e8e8e8;
}
 .wide.w-100 option{
     min-height: 40px;
}
 .custom-control-input:focus ~ .custom-control-label::before{
     box-shadow: none;
}
 .odr-box a {
     font-size: 18px;
     color: #111111;
     font-weight: 700;
}
 .account-box{
     text-align: center;
     background: #ffffff;
     padding: 30px;
     border: 1px solid #000000;
}
 .bottom-box {
     border-top: 1px solid #eee;
     margin-bottom: 30px;
     margin-top: 30px;
     padding-top: 15px;
}
 .bottom-box .account-box {
     min-height: 205px;
}
 .account-box {
     border: 2px solid #000000;
     margin-top: 15px;
}
 .my-account-page a{
     color: #000000;
}
 .my-account-page a:hover{
     color: rgb(175, 220, 200);
}
 .service-icon i{
     font-size: 34px;
}
 .my-account-page a:hover i{
}
 .service-desc p{
     font-size: 16px;
}
 .service-desc h4{
     text-decoration: underline;
     font-size: 18px;
     font-weight: 700;
}
 .service-icon a{
     background: rgba(0, 0, 0, 0.9);
     -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
     -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
     transition: transform ease-out 0.1s, background 0.2s;
}
 .service-icon a{
     display: inline-block;
     font-size: 0px;
     cursor: pointer;
     margin: 15px 0px;
     width: 90px;
     height: 90px;
     line-height: 110px;
     border-radius: 50%;
     text-align: center;
     position: relative;
     z-index: 1;
     color: #ffffff;
}
 .service-icon a::after {
     pointer-events: none;
     position: absolute;
     width: 100%;
     height: 100%;
     border-radius: 50%;
     content: '';
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box;
     content: "";
     top: 0;
     left: 0;
     padding: 0;
     z-index: -1;
     box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
     opacity: 0;
     -webkit-transform: scale(0.9);
     -moz-transform: scale(0.9);
     -ms-transform: scale(0.9);
     transform: scale(0.9);
}
 .service-icon a:hover::after{
     -webkit-animation: sonarEffect 1.3s ease-out 75ms;
     -moz-animation: sonarEffect 1.3s ease-out 75ms;
     animation: sonarEffect 1.3s ease-out 75ms;
}
 .service-icon a:hover{
     background: rgba(251, 183, 20, 1);
     -webkit-transform: scale(0.93);
     -moz-transform: scale(0.93);
     -ms-transform: scale(0.93);
     transform: scale(0.93);
     color: #fff;
}
 @-webkit-keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(175, 220, 200), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(175, 220, 200), 0 0 0 10px rgba(0, 53, 68, 0.5);
         -webkit-transform: scale(1.5);
         opacity: 0;
    }
}
 @-moz-keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(175, 220, 200), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(175, 220, 200), 0 0 0 10px rgba(0, 53, 68, 0.5);
         -moz-transform: scale(1.5);
         opacity: 0;
    }
}
 @keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(175, 220, 200), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(0, 53, 68, 0.5);
         transform: scale(1.5);
         opacity: 0;
    }
}
 .add-pr a{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .wishlist-box-main{
     padding: 70px 0px;
}
 .services-box-main{
     padding: 70px 0px;
}
 .contact-box-main{
     padding: 70px 0px;
}
 .contact-info-left{
     padding: 20px;
     /* background: url("images/contact-bg.jpg") no-repeat center; */
	 background-size: cover;
}
 .contact-info-left h2{
     font-size: 22px;
     font-weight: 700;
}
 .contact-info-left p{
     margin-bottom: 15px;
}
 .contact-info-left ul li{
     margin-bottom: 12px;
}
 .contact-info-left ul li p{
     font-size: 16px;
     color: #222222;
     font-weight: 300;
     padding-right: 16px;
     padding-left: 25px;
     line-height: 24px;
     position: relative;
}
 .contact-info-left ul li p i{
     position: absolute;
     left: 0;
     top: 5px;
     padding-right: 6px;
     color: rgb(175, 220, 200);
}
 .contact-info-left ul li p a:hover{
     color: rgb(175, 220, 200);
}
 .contact-form-right{
     padding: 20px;
}
 .contact-form-right h2{
     font-size: 24px;
     font-weight: 700;
}
 .contact-form-right p{
     margin-bottom: 15px;
}
 .contact-form-right .form-group .form-control::-moz-placeholder{
     color: #999999;
}
 .contact-form-right .form-group .form-control{
     border-radius: 0px;
     min-height: 40px;
}
 .contact-form-right .form-group {
     margin-bottom: 30px;
     position: relative;
}
 .contact-form-right .form-group .form-control:focus{
     border: 1px solid rgb(175, 220, 200);
     box-shadow: none;
}
 .submit-button button{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .help-block.with-errors{
     position: absolute;
     right: 0;
     background: red;
     color: #fff;
     padding: 0px 15px;
}
 .help-block ul li{
     color: #fff;
}
 .shop-detail-box-main{
     padding: 70px 0px;
}
 .single-product-slider .carousel-control-prev {
     bottom: auto;
     background: #111111;
     width: 6%;
     padding: 10px 0;
     background-image: none;
     top: 40%;
}
 .single-product-slider .carousel-control-next {
     bottom: auto;
     background: #111111;
     width: 6%;
     padding: 10px 0;
     background-image: none;
     top: 40%;
}
 .single-product-slider .carousel-indicators li img {
     opacity: 0.5;
}
 .single-product-slider .carousel-indicators li.active img {
     opacity: 1;
}
 .carousel-indicators{
     position: relative;
     bottom: 0;
     background: #000000;
}
 .carousel-indicators li{
     width: 30%;
     height: 100%;
     cursor: pointer;
}
 .single-product-details h2{
     color: #000000;
     font-weight: 700;
     font-size: 24px;
}
 .single-product-details h5 {
     color: rgb(175, 220, 200);
     font-weight: 700;
     font-size: 18px;
}
 .single-product-details h5 del {
     font-size: 13px;
     color: #666;
}
 .available-stock span {
     font-size: 15px;
}
 .available-stock span a {
     color: rgb(175, 220, 200);
}
 .single-product-details h4{
     font-size: 18px;
     font-weight: 700;
     margin-top: 20px;
}
 .single-product-details p{
     font-size: 16px;
     margin-bottom: 20px;
}
 .single-product-details ul{
     display: inline-block;
     width: 100%;
     /* border: 1px #000000 dashed; */
     margin-bottom: 30px;
}
 .single-product-details ul li{
     /* width: 50%; */
     float: left;
     padding: 0px 3px;
}
 .size-st .bootstrap-select > .dropdown-toggle.btn-light{
     background: #000000;
     border: none;
     border-radius: 0px;
     color: #ffffff;
}
 .quantity-box input{
     width: 100%;
}
 .price-box-bar{
     margin-bottom: 30px;
}
 .price-box-bar a{
     padding: 5px 30px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .add-to-btn .add-comp {
     float: left;
}
 .add-to-btn .share-bar {
     float: right;
}
 .add-comp a{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .share-bar{
}
 .share-bar a {
     background: rgb(175, 220, 200);
     color: #ffffff;
     padding: 5px 10px;
     display: inline-block;
     width: 34px;
     text-align: center;
}
 .share-bar a {
}
 .featured-products-box{
}
 .featured-products-box .owl-nav{
     position: absolute;
     margin: 0 auto;
     left: 0;
     right: 0;
     bottom: -20px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-prev{
     background: #000000;
     color: #ffffff;
     width: 38px;
     height: 38px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-next{
     background: #000000;
     color: #ffffff;
     width: 38px;
     height: 38px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-prev:hover, .featured-products-box .owl-nav button.owl-next:hover{
     background: rgb(175, 220, 200);
}
 .table-responsive .table td, .table-responsive .table th {
     vertical-align: middle;
     white-space: nowrap;
}
 
.card-outline-secondary .card-body a{
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	padding: 12px 25px;
	border: none;
}







.dfkj1{
  display: none !important;
}


.img123{
  height: 307px;
}

.ther1{
  font-family: 'Times New Roman', Times, serif;
  text-align: left;
  /* margin-bottom: 70px !important; */
}


.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.stat {
  /* text-align: center; */
  flex: 1;
  padding: 20px;
  border-bottom: 1px solid white;
  border-radius: 5px;
}
.nostat {
  border: none;
}

.stat h2 {
  font-size: 1.5em;
  margin: 0;
}

.stat p {
  font-size: 1.2em;
  font-weight: 600;
  color: white;
}

.green {
  color: #F7CF47;
  font-weight: bold;
}

@media  only screen and (min-width: 1000px) and (max-width: 1925px){
  .change-align{
    right : 20%; 
    top : -350px !important;
  }
  .change-gif-video{
    width: 70%;
    margin-left: 30%;
    background-color: rgb(25, 18 ,43);
  }
}

@media  only screen and (min-width: 800px) and (max-width: 999px){
  .change-gif-video{
    width: 70%;
    margin-left: 30%;
  
  }
  .change-align{
    right : 18%; 
    top : -470px !important;
  }
  .ther1{
    font-size:  50px !important;
  }
  
}
@media  only screen and (min-width: 526px) and (max-width: 799px){
  .change-gif-video{
    width: 70%;
    margin-left: 30%;
  
  }
  
  .change-align{
    right : 10%; 
    top : -530px !important;
  }
  .ther1{
  font-size:  40px !important;
  }
}
.eedfjjn:hover{
  box-shadow: 1px 2px 2px rgb(25, 18 ,43) !important;
}
/* .change-visible{
  visibility:  hidden;
} */
@media  only screen and (min-width: 1400px) and (max-width: 10525px){
  .efeeee{
    height: 1300px !important;
  }
.button-change1{
  top : 830px !important
}
}

  @media  only screen and (min-width: 200px) and (max-width: 725px){
    .change-size-section{
      font-size: 30px !important;
      width: 80% !important;
    }
    .change-size-section3{
      font-size: 26px !important;
      font-weight: 500 !important;
      width: 80% !important;
      margin:auto !important
    }
    .change-size-section2{
      font-size: 15px !important;
      padding  :10px 20px
    }
.change-padding-home-section{
  padding  : 0px !important
}

  }
  .changes-mobile-view-p2{
    display: none !important;
  
  }
  @media  only screen and (min-width: 200px) and (max-width: 525px){
    .change-padd-nav {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    border: none !important;
}
.change-padd-nav1 {
  
  border: none !important;
  }
  .change-padd-nav2 {
      border-bottom: 1px solid !important;
    
  }
    .change-nav-hide{
      display: none !important;
      border: none !important;
      visibility: hidden;
    }
.changes-mobile-view-p1{
  display: none !important;

}
.changes-mobile-view-p2{
  display: block !important;

}
.change-size-main-page{
  width: 100% !important;

}
.change-size-main-page1
{
  font-size: 2.375rem !important ;
        line-height: 42px  !important;
        font-weight: 700  !important;
}
.details1{
  margin-top: 20px !important;
  width: 100% !important;
}
    .reviewItem {
      width: 90% !important;
    }
    .change-mobile-veiw1{
      display: none !important;
    }
    .change-mobile-veiw{
      display: block !important;
    }
    .change-view1{
      display: none !important;
    }
    .change-view{
      display: block !important;
    }
.close {
  
  width: 15% !important;

}
.wopdkdopk{
  top : -300px !important; 
}

  .hkkuiuuouo{
   flex-direction: column !important;
   padding : 10px 5px !important
 }
 .efjksefkj{
   /* text-align: center ; */
   padding: 10px 20px !important;
 }
 .efyiyeeee{
   padding : 0px 10px !important;
   width : 100% !important;
 }
 .ewewe{
 padding : 0px 0px 0px 50px !important;
 }
  .hjhhkkhkhkku{
   font-size: 20px !important;
   margin-bottom:  60px !important;
 }
  .oiuoiip{
    width: 95% !important;
    margin: 50px auto !important;
  }
  .jkjjjljl{
    background: url(assets/images/ban-35.jpeg);
    background-size: cover !important;
      background-position: center;
  }
  .dfdjkfjk{
    position: inherit !important; 
  }
  .jhhjhh{
    width: 90% !important;
    margin: auto !important;
  }
  .wwduiouo{
    width: 100% !important;
    height: 400px !important;
  }
    .change-align{
      right : 5%; 
      top : -650px !important;
    }
    .ther1{
    font-size:  29px !important;
    margin-bottom: 20px !important;
    }
    .change-text-size{
      font-size: 15px !important;
      /* font-size: 18px !important; */
    }
  .attr-nav{
    top: 20px !important;
    right: 10px !important;   
  }
  
  .img123{
    height: auto !important;
  }
  .dfkj{
    display: none !important;
  }
  .dfkj1{
    display: block !important;
  }
  .ther{
    top :  -306px !important;
    position: absolute;
    padding : 0px !important
      }
  /* .ther1{
    font-size :  30px !important
      } */
      .thers123{
        padding:  0px !important;
        margin: 0px !important;
      }
  .thers{
    top :  -420px !important;
    position: absolute;
    padding : 0px !important
      }
  .ther1s{
    font-size :  14px !important
      }
  .ther3{
    height: 420px;
      }
  .ther4{
    max-height: 420px !important;
      }
      .efeeee{
        height: 227px!important;
        object-fit: cover !important;
      }
      .button-change1{
        position: absolute;
       
     
        font-size: 7px !important;
        font-weight: bold !important;
        left: 11% !important;
        position: absolute;
        top: 311px !important;
        width: 61px !important;
        padding: 2px 3px !important;
        border : 1px solid #F7CF47 !important;
      }
    }

    .top-search {
      display:  block;
 }


 
.ftco-section {
  padding: 6em 0;
  position: relative; }
  @media (max-width: 767.98px) {
    .ftco-section {
      padding: 6em 0; } }

      .testimony-section {
        position: relative; }
        .testimony-section .owl-carousel {
          margin: 0; }
        .testimony-section .owl-carousel .owl-stage-outer {
          padding: 1em 0 2em 0;
          position: relative; }
        .testimony-section .owl-nav {
          position: absolute;
          top: 100%;
          width: 100%; }
          .testimony-section .owl-nav .owl-prev,
          .testimony-section .owl-nav .owl-next {
            position: absolute;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-top: -10px;
            outline: none !important;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
            opacity: 0; }
            .testimony-section .owl-nav .owl-prev span:before,
            .testimony-section .owl-nav .owl-next span:before {
              font-size: 30px;
              color: rgba(0, 0, 0, 0.5);
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -ms-transition: all 0.3s ease;
              transition: all 0.3s ease; }
            .testimony-section .owl-nav .owl-prev:hover span:before, .testimony-section .owl-nav .owl-prev:focus span:before,
            .testimony-section .owl-nav .owl-next:hover span:before,
            .testimony-section .owl-nav .owl-next:focus span:before {
              color: #fff; }
          .testimony-section .owl-nav .owl-prev {
            left: 50%;
            margin-left: -80px; }
          .testimony-section .owl-nav .owl-next {
            right: 50%;
            margin-right: -80px; }
        .testimony-section:hover .owl-nav .owl-prev,
        .testimony-section:hover .owl-nav .owl-next {
          opacity: 1; }
        .testimony-section:hover .owl-nav .owl-prev {
          left: 50%;
          margin-left: -80px; }
        .testimony-section:hover .owl-nav .owl-next {
          right: 50%;
          margin-right: -80px; }
        .testimony-section .owl-dots {
          text-align: center; }
          .testimony-section .owl-dots .owl-dot {
            width: 10px;
            height: 10px;
            margin: 5px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.1); }
            .testimony-section .owl-dots .owl-dot.active {
              background: #e86ed0; }

              
@media (min-width: 992px) {
  .wrap-about .heading-section {
    margin-left: -120px;
    background: #fff;
    -webkit-box-shadow: -20px 5px 14px -15px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: -20px 5px 14px -15px rgba(0, 0, 0, 0.13);
    box-shadow: -20px 5px 14px -15px rgba(0, 0, 0, 0.13); } }
    .ftco-section-parallax .heading-section-white h2 {
      font-weight: 400 !important; }

      .heading-section .subheading {
        font-size: 18px;
        display: inline-block;
        margin-bottom: 0;
        color: #e86ed0;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        position: relative; }
        .heading-section .subheading:after {
          position: absolute;
          top: 50%;
          left: -60px;
          content: '';
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 50px;
          height: 1px;
          margin-top: -1px;
          background: #e86ed0; }
        .heading-section .subheading:before {
          position: absolute;
          top: 50%;
          right: -60px;
          content: '';
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 50px;
          height: 1px;
          margin-top: -1px;
          background: #e86ed0; }
      
      .heading-section h2 {
        font-size: 40px;
        font-weight: 600; }
        @media (max-width: 767.98px) {
          .heading-section h2 {
            font-size: 28px; } }
      
      .heading-section.heading-section-white .subheading {
        color: rgba(255, 255, 255, 0.9); }
      
      .heading-section.heading-section-white h2 {
        font-size: 40px;
        color: #fff; }
      
      .heading-section.heading-section-white p {
        color: rgba(255, 255, 255, 0.9); }
        .ftco-animate {
          opacity: 0;
          visibility: hidden; }

          .testimony-wrap {
            display: block;
            position: relative;
            background: rgba(255, 255, 255, 0.1);
            padding: 0 20px;
            border: 1px solid rgba(0, 0, 0, 0.02);
            -webkit-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
            -moz-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
            box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14); }
            .testimony-wrap .user-img {
              width: 80px;
              height: 80px;
              position: relative;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              border-radius: 50%; }
            .testimony-wrap .name {
              font-weight: 500;
              font-size: 20px;
              margin-bottom: 0;
              color: #000000; }
            .testimony-wrap .position {
              font-size: 16px;
              color: #cccccc; }
                          








 .the{
   height: 430px !important;
   /* object-fit: cover !important; */
 }


 .the1{
   height: 100px !important;
 }

 .hr-btn:hover{
   background-color: black !important;
   transition: .6s !important;
 }
 .special-list{
   height: auto !important;
 }

 @media (min-width: 768px) and (max-width: 991px) {
  nav.navbar.bootsnav .navbar-header{
      width: auto !important;
      /* margin-top: 54px !important; */

 }
}
.ml-auto{
  margin-left: 22% !important;
  /* margin-left: 6% !important; */
  /* margin-left: 13% !important; */
  /* font-family: 'Poppins', sans-serif !important; */
}
.the456  {
  /* margin-left: 13% !important; */
  font-family: 'Poppins', sans-serif !important;
}
.gy {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  object-fit: contain !important;
  width: 100%;


  }


  .carousel1 .carousel-caption {
    /* position: absolute; */
    top: 5% !important;
    /* bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 400px;
    min-height: 400px;
    width: 100%; */
  }
  .carousel1 .carousel-caption h1 {
    color: #268053;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 35px;
  }
  .carousel1 {
    position: relative;
    width: 100%;
    height: 400px;
    min-height: 300px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}
.thr{
  list-style: circle !important;
}
.thr > li{
  list-style: circle !important;
}
/* .hjjhjh{
  background-color: transparent !important;
} */
.the456 > Link{
  color : white !important
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
td {
  text-align: center;
}



.header1 {
  color: hsl(233, 13%, 49%);
  margin: 3.3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.toggle {
  margin-top: 2rem;
  color: hsl(234, 14%, 74%);
  display: flex;
  align-items: center;
}
.toggle-btn {
  margin: 0 1rem;
}
.checkbox1 {
  display: none;
}

.sub {
  background: rgb(25, 18, 43);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 25px;
    width: 50px; */
  height: 1.6rem;
  width: 3.3rem;
  border-radius: 1.6rem;
  padding: 0.3rem;
}
.circle {
  background-color: #fff;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
}
.checkbox1:checked + .sub {
  justify-content: flex-end;
}

.cards1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card1 {
  background: #fff;
  color: hsl(233, 13%, 49%);
  border-radius: 0.8rem;
}

.cards1 .card1.active {
  background: rgb(25, 18, 43);
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
}
.ul1 {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ul1 li {
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.7rem 0;
  font-size: 12px;
}
.ul1 li.price {
  font-size: 3rem;
  color: rgb(25, 18, 43);
  padding-bottom: 1rem;
}
.shadow1 {
  box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
}

.card1.active .price {
  color: #fff;
}

.btn1 {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgb(247, 207, 71);
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
}
.active-btn {
  background: #fff;
  color: rgb(25, 18, 43);
}
.bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
}
.card1.active .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.253);
}
.pack {
  font-size: 1.1rem;
}

@media (max-width: 280px) {
  .ul1 {
    margin: 1rem;
  }
  .h11 {
    font-size: 1.2rem;
  }
  .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }
  .cards1 {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .card1 {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }
  .cards1 .card1.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .ul1 {
    margin: 20px;
  }
  .cards1 {
    display: flex;
    flex-direction: column;
  }
  .card1 {
    margin-bottom: 1rem;
  }
  .cards1 .card1.active {
    transform: scale(1);
  }
}
.back-to-top12 {
  position: fixed;
  top: 55%;
  right: 9px;
  z-index: 9999;
  width: 32px;
  height: 35px;
  text-align: center;
  line-height: 25px;
  background: transparent;
  color: #F7CF47;
  cursor: pointer;
  border: 0;
  border-radius: 0px;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  font-size: 28px;
  transform: rotate(180deg);
  display: none;
  /* border: 1px solid white; */
}
@media (min-width: 320px) and (max-width: 414px) {
  .back-to-top12 {
   
    display: block !important;
  }
  .cards1 {
    display: flex;
    flex-direction: column;
  }
  .card1 {
    margin-bottom: 1rem;
  }
  .cards1 .card1.active {
    transform: scale(1);
  }
}

.efkekej:hover{
  /* border-bottom: 1px solid #F7CF47 !important; */
  /* padding  :10px 0px !important */
}
.efkekej{
  padding  :10px 0px !important
}
.footer-link ul li a:hover{
  color:#F7CF47 !important
}
.seflkweflk{
  padding: 13px 0px 0px 0px !important;
}
/* @media (min-width: 120px) and (max-width: 514px) { */
  .dfjnjn{
    width:24% !important;
    margin :0px auto !important
  }
/* } */
.efwfewfef{
  /* display:none !important */
  display:block !important
}
.efwfewfef1{
  display:none !important
  /* display:block !important */
}
.change-mobile{
  display: none;
}
@media (min-width: 120px) and (max-width: 414px) {
  .change-desktop{
    display: none !important;
  }
  .change-mobile{
    display:  block !important;
  }
  .change-mob-pho{
    max-width:214px !important
  }
  .edfsfd{
    flex-direction: column;
  }
  /* .dfjnjn{
    width:24% !important;
    margin :0px 14px !important
  } */
  .change-thres{
    left: 22% !important
  }
  .rdgergkjrg{
    font-size: 11px!important;
  }
  .rferlkerlkerkl{
    width: 100%!important;
  }
  .fcjdfgljkk{
    flex-wrap: inherit;
  }
  .dlfkflkl{
    width: 100%!important;
  }
  .fgdfgdg{
    top:-380px !important;
    left: -50px;

  }
  .fdgurdgjh{

    height: 517px !important;
  }
  .chage-mobile-head{
    position: inherit!important;
    left: 0px;
  }
  .change-text-size123{
    font-size: 24px!important;
  }
  .carrousel-item7 {
  
    left: 4px !important;
  }
}
@media (min-width: 414px) and (max-width  : 768px) {
  .card1 {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .cards1 .card1.active {
    transform: scale(1);
  }
}
@media (min-width: 768px) and (max-width: 1046px) {
  .cards1 {
    display: flex;
  }
  .card1 {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .cards1 .card1.active {
    transform: scale(1);
  }
}
